import React, { useMemo } from "react";
import useRouter from "@/res/components/router";
import Link from "next/link";

const NavLink = ({ logoComponent, children, ...rest }) => {
    const { href, ...props } = rest;

    const router = useRouter();
    const _href = useMemo(() => {
        let _href = href;
        if (!_href) return null;

        if (_href[0] !== "/") _href = "/" + _href;
        if (_href[_href.length - 1] !== "/") _href = _href + "/";
        return _href;
    }, [])
    const link = href ? `/${router.locale}${_href}` : "";
    const current = router.asPath?.split("?")?.[0] === _href || router.asPath?.split("?")?.[0] === `/${router.locale}${_href}`;

    // Purely to stop it from triggering any dropdown parent.
    const click = (e) => {
        e.stopPropagation();

        // By preventing default you don't follow the link URL.
        if (current) e.preventDefault();
    }

    return (
        <Link href={props.external ? href : link} passHref>
            <a {...props} className="nav__link" aria-current={ current ? "page" : null } onClick={click}>
                <span>{logoComponent}</span>
                <span>
                    {children}
                </span>
            </a>
        </Link>
    );
};

export default NavLink;
