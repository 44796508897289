import * as Sentry from "@sentry/nextjs";
import useApi from "@/res/hooks/SynegaApi";

export const IS_SERVER = typeof window === "undefined";
export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const collapseData = (data) => {
    if (!data) return {};

    Object.keys(data).forEach(key => {
        if (data[key] && typeof data[key] === "object") {
            if ("_ii" in data[key] && "label" in data[key]) {
                try {
                    data[key] = data[key].label;
                } catch (e) {
                    data[key] = undefined;
                }
            } else {
                data[key] = collapseData(data[key])
            }
        }
    });

    return data;
}

export const hasProps = (jsx) =>
    Object.prototype.hasOwnProperty.call(jsx, "props");

const reduceJsxToString = (previous, current) =>
    previous + innerText(current);

export const innerText = (jsx) => {
    // Empty
    if (
        jsx === null ||
        typeof jsx === "boolean" ||
        typeof jsx === "undefined"
    ) {
        return "";
    }

    // Numeric children.
    if (typeof jsx === "number") {
        return jsx.toString();
    }

    // String literals.
    if (typeof jsx === "string") {
        return jsx;
    }

    // Array of JSX.
    if (Array.isArray(jsx)) {
        return jsx.reduce(reduceJsxToString, "");
    }

    // Children prop.
    if (
        hasProps(jsx) &&
        Object.prototype.hasOwnProperty.call(jsx.props, "children")
    ) {
        return innerText(jsx.props.children);
    }

    // Default
    return "";
}

export const timestamp = (value) => {
    if (!value) return "Invalid";

    const _time = new Date(value);
    return _time.toISOString()
        .replace("T", " ")
        .replace(/\.\d{3}Z$/, "");
}

export const downloadFile = (url, file_name = "file") => {
    const api = useApi(3);
    api.get(url, null, { responseType: "blob" }).then(async res => {
        let content_type = res?.data?.type ?? res?.headers?.["content-type"];
        if (content_type !== "application/json") {
            file_name = res?.headers?.["content-name"] ?? file_name;
            return res?.data;
        }

        console.log(JSON.parse(await res.data.text()))
        throw new Error("Could not open document.");
    }).then(blob => {
        if (!blob) return;

        const url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.download = file_name;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }).catch(error => {
        Toast.error("", "Noe gikk gale, klarte ikke å hente dokumentet.");
        Sentry.captureException(error);
        console.log(error?.response?.data?.messages ?? error);
    })
}
