import React, { useEffect } from "react";
import useForm from "@/res/hooks/Form/useForm";
import { resolvePathWithNull } from "@/res/helpers/ObjectHelpers";

const useNumberInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "numberInput";
    const elementId = `useNumberInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePathWithNull(context?.state, key) : values[key];

    const setValue = (value) => {
        forceValue(key, value);
    };

    const isValid = (v = null) => {
        let item = document.getElementById(elementId);
        // let _value = v != null ? v : value;
        // let msg = "";

        item?.setCustomValidity?.("");
        return true;
    }

    const handleChangeMiddleman = (e) => {
        handleChange(e)
        isValid(e?.target?.value);
    }

    const render = () => {
        return (
            <div>
                { props?.label &&
                <label htmlFor={elementId}>
                    {props?.label}
                </label>
                }
                <input
                    id={elementId}
                    name={key}
                    type="number"
                    placeholder={ props?.placeholder ?? "" }
                    value={ value ?? rule?.default ?? "" }
                    min={ rule?.minValue }
                    max={ rule?.maxValue }
                    onChange={ props?.onChange ?? handleChangeMiddleman}
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    required={ rule?.required ?? true}
                    className={ props?.class }
                    disabled={ rule?.disabled ?? false }
                />
            </div>
        );
    };

    return {
        value, render, setValue, isValid,
    };
};

export default useNumberInput;
