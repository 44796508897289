import React, { useEffect } from "react";
import { useServiceContext } from "../../context/ServiceContext";
import useHiddenInput from "@/res/hooks/Form/useHiddenInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const PriceSelection = (props) => {
    const serviceContext = useServiceContext();
    const data = (typeof props?.prices) === "function" ? props?.prices?.(serviceContext?.company) : props?.prices;

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [])

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [serviceContext?.state?.pricing?.index])

    // Can only proceed from evaluation returns true.
    const evaluate = () => {
        if (
            serviceContext?.state?.pricing?.index >= 0 &&
            data?.[serviceContext?.state?.pricing?.index]?.details?.length === 1
        ) {
            return true;
        }

        if (
            serviceContext?.state?.pricing?.index >= 0 &&
            data?.[serviceContext?.state?.pricing?.index]?.details?.length > 1 &&
            serviceContext?.state?.pricing?.subscription_index >= 0
        ) {
            return true;
        }

        return false;
    };

    const hiddenInput = useHiddenInput({ key: "pricing", context: serviceContext });
    const selectPricingStructure = (index, sub_index = -1) => {
        if (data?.[index] == null) {
            console.log("Couldn't fetch pricing data.");
            return;
        }

        delete serviceContext?.state?.pricing?.compatibleCategories;
        const item = data?.[index];
        hiddenInput.setValue({
            index: index,
            name: item.name,
            type: item.type,
            subscription_index: sub_index,
            subscription_plan: (sub_index >= 0 ? item?.details?.[sub_index]?.title : null) ?? null,
            compatibleCategories: item.compatibleCategories ?? null,
            yearly: item.yearly ?? null,
            monthly: item.monthly ?? null,
            hourly: item.hourly ?? null,
        });

        serviceContext?.dispatch?.({ type: serviceContext?.actions?.PAGE_NEXT, force: true });
    };

    if ((data?.length ?? 0) === 0) {
        return (
            <>
                { pageTitle("Select pricing") }

                <div className="container funnel-container funnel-container-bottom-buffer">
                    <div className="row justify-content-center">
                        <div className="funnel-big-card no-click" style={{ borderColor: "#282828" }}>
                            <div className="service-card-header">
                                Ingen tilgjengelige priser
                            </div>

                            <div className="service-card-description no-click">
                                <p>
                                    Det er ingen tilgjengelige priser å tilby. Dersom du er en kunde og det skulle vært en
                                    pris her så vennligst informer kundeservice at noe er gale.
                                </p>
                                <p>
                                    Oppgi denne koden: { `#${serviceContext?.state?.shared?.service_code}-1000` }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            { pageTitle("Select pricing") }

            <div className="container funnel-container funnel-container-bottom-buffer">
                <div className="row justify-content-center">
                    { data?.map((item, index) => {
                        if (item?.for_business != null && item?.for_business !== serviceContext?.state?.client?.is_business) {
                            return null;
                        }

                        return (
                            <div className="col-11 col-sm-8 col-lg-7" key={index}>
                                <div
                                    onClick={() => item?.details?.length === 1 && selectPricingStructure(index)}
                                    className={
                                        "price-card-container " +
                                        (Number(serviceContext?.state?.pricing?.index ?? -1) === index ? "selected " : "")
                                    }
                                >
                                    <div className="price-card-header">
                                        { item?.name }
                                    </div>

                                    <div className="price-card-body">
                                        { item?.details?.length === 1 &&
                                        <>
                                            <div className="toggle-container">
                                                { item?.details?.[0]?.title }
                                                <div className={
                                                    "toggle-box " +
                                                    (Number(serviceContext?.state?.pricing?.index ?? -1) === index ? "selected " : "")
                                                }>
                                                    <span/>
                                                </div>
                                            </div>
                                            { item?.details?.[0]?.description }
                                        </>
                                        }
                                        { item?.details?.length > 1 && item?.details?.map((sub, sub_index) => {
                                            return (
                                                <div className="price-subscription" onClick={() => selectPricingStructure(index, sub_index)} key={"sub" + sub_index}>
                                                    <div className="toggle-container">
                                                        { sub.title }
                                                        <div className={
                                                            "toggle-box " +
                                                            (Number(hiddenInput.value?.subscription_index) === sub_index ? "selected " : "")
                                                        }>
                                                            <span/>
                                                        </div>
                                                    </div>
                                                    { sub.description }
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default PriceSelection;
