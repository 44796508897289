import React from "react";
import DescribeHelp from "@/res/components/ServicePages/DescribeHelp";
import Associates from "@/res/components/ServicePages/Associates";
import AssistanceCategory from "@/res/components/ServicePages/AssistanceCategory";
import AnnualReportAuditing from "@/res/components/ServicePages/CountrySpecific/Norway/AnnualReportAuditing";
import AcceptPrivacyPolicy from "@/res/components/ServicePages/AcceptPrivacyPolicy";
import FormerAccountantAuditor from "@/res/components/ServicePages/FormerAccountantAuditor";
import Basics from "@/res/components/ServicePages/ServiceSpecific/auno/Basics";

const priceData = (props) => [
    {
        type: "PAYG",
        name: "Betal per minutt",
        details: [{
            title: "1.460,- per time",
            description:
                <>
                    <p>
                        Betal kun for minuttene som brukes, ikke per påbegynt kvarter.
                    </p>
                </>,
        }],
    },
    {
        type: "FIXED",
        name: "Fastpris",
        details: [{
            title: "Etter avtale",
            description:
                <>
                    <p>
                        Få et uforpliktende tilbud som du kan vurdere før arbeidet begynner.
                    </p>
                </>,
        }],
    },
];

const categoryData = [
    {
        internal_id: 1,
        name: "Revisjon",
        color: "#77A6C3",
        requires_consultant_signature: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Selecting starting date and auditing software.";
                    return <Basics {...props}
                        optionsLabel="Hvilket regnskapssystem benytter virksomheten?"/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring previous accountants/auditors.";
                    return <FormerAccountantAuditor {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Configuring reporting responsibilities.";
                    return <AnnualReportAuditing {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 2,
        name: "Skattefunn",
        color: "#43A1A7",
        requires_consultant_signature: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Specifying category of assistance required.";
                    return <AssistanceCategory {...props} options={discoveryOptions} saveAs="discovery"/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props} title="Annet" label="Er det noe spesielt vi bør vite? (valgfritt)" />
                },
            ],
        ],
    },
    {
        internal_id: 3,
        name: "Avvikling",
        color: "#E794A8",
        requires_consultant_signature: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Specifying category of assistance required.";
                    return <AssistanceCategory {...props} options={dissolutionOptions} saveAs="dissolution"/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props} title="Annet" label="Er det noe spesielt vi bør vite? (valgfritt)" />
                },
            ],
        ],
    },
    {
        internal_id: 4,
        name: "Omorganisering",
        color: "#77C3B1",
        requires_consultant_signature: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props} required label="Fortell oss hva du behøver hjelp til innenfor omorganisering" />
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 5,
        name: "Due Dilligence",
        color: "#B2B7E5",
        requires_consultant_signature: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Specifying category of assistance required.";
                    return <AssistanceCategory {...props} options={dueDiligenceOptions} saveAs="due_diligence"/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props} title="Annet" label="Er det noe spesielt vi bør vite? (valgfritt)" />
                },
            ],
        ],
    },
    {
        internal_id: 6,
        name: "Kapitalbekreftelser",
        color: "#F2CF89",
        requires_consultant_signature: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Specifying category of assistance required.";
                    return <AssistanceCategory {...props} options={capitalGainsOptions} saveAs="capital_gains"/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
                    return <Associates {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props} title="Annet" label="Er det noe spesielt vi bør vite? (valgfritt)" />
                },
            ],
        ],
    },
    {
        internal_id: 7,
        name: "Annen rådgivning",
        color: "#6DB9CA",
        requires_consultant_signature: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props} required label="Fortell oss hva du ønsker rådgivning innenfor" />
                },
            ],
        ],
    },
];

const dueDiligenceOptions = [
    {
        label: "Velg et bistandsalternativ...",
    },
    {
        label: "Finansiell Due Dilligence",
    },
    {
        label: "Skattemessig Due Dilligence",
    },
    {
        label: "Verdivurdering av virksomhet",
    },
    {
        label: "Vurdering av kjøpesumberegning",
    },
];

const capitalGainsOptions = [
    {
        label: "Velg et bistandsalternativ...",
    },
    {
        label: "Kapitalforhøyelse ved innskudd",
    },
    {
        label: "Kapitalforhøyelse ved gjeldskonvertering",
    },
    {
        label: "Fondsemisjon",
    },
    {
        label: "Kapitalnedsettelse",
    },
    {
        label: "Aksjespleis",
    },
    {
        label: "Aksjesplitt",
    },
    {
        label: "Annet",
    },
];

const dissolutionOptions = [
    {
        label: "Velg et bistandsalternativ...",
    },
    {
        label: "Oppsett av avviklingsregnskap",
    },
    {
        label: "Revisjon av avviklingsregnskap",
    },
    {
        label: "Rådgivning knyttet til avviklingsoppgjøret",
    },
];

const discoveryOptions = [
    {
        label: "Velg et bistandsalternativ...",
    },
    {
        label: "Bekreftelse av prosjektregnskap",
    },
    {
        label: "Hjelp til søknad",
    },
    {
        label: "Hjelp med årsrapport",
    },
    {
        label: "Rådgivning",
    },
];

const softwareData = (t) => [
    {
        label: "Velg regnskapssystem...",
    },
    {
        internal_id: 1,
        label: "Fiken",
    },
    {
        internal_id: 3,
        label: "Tripletex",
    },
    {
        internal_id: 12,
        label: "DNB",
    },
    {
        internal_id: 2,
        label: "Visma eAccounting",
    },
    {
        internal_id: 15,
        label: "Visma.net",
    },
    {
        internal_id: 14,
        label: "24sevenoffice",
    },
    {
        internal_id: 999,
        label: "Annet",
    },
]

const Auno = {
    // init: (context) => {
    //     let payload = {}
    //     setPath(payload, "config.check_aml", true);
    //     context?.dispatch({ type: context?.defaultAction, payload: payload});
    // },

    props: () => {
        return {
            checkAml: true,
            prices: priceData,
            services: categoryData,
            softwares: softwareData,
        };
    },
}

export default Auno;
