import React, { createContext, useContext, useMemo } from "react";
import useContextAbstraction from "@/res/context/context-abstraction";

const GenericContext = createContext();

export const GenericWrapper = ({ children, ...props }) => {
    const context = useContextAbstraction(props);

    const contextValue = useMemo(() => {
        return context;
    }, [context.state, context.dispatch]);

    return (
        <GenericContext.Provider value={contextValue}>
            { children}
        </GenericContext.Provider>
    );
};

export function useGenericContext() {
    return useContext(GenericContext);
}
