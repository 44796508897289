import { uuid } from "@/res/helpers/ObjectHelpers";

class Toast {
    static get MAX_TOASTS() {
        return 4;
    }

    static #toasts = [];
    static #interval = null;
    static #rootItem = null;

    static success = (header, body, time_in_seconds = null) => {
        this.#add(header, body, "SUCCESS", time_in_seconds);
    };

    static info = (header, body, time_in_seconds = null) => {
        this.#add(header, body, "INFO", time_in_seconds);
    };

    static warn = (header, body, time_in_seconds = null) => {
        this.warning(header, body, time_in_seconds);
    };

    static warning = (header, body, time_in_seconds = null) => {
        this.#add(header, body, "WARNING", time_in_seconds);
    };

    static error = (header, body, time_in_seconds = null) => {
        this.#add(header, body, "ERROR", time_in_seconds);
    };

    /* Handles adding new toasts and initiating processes. */
    static #add = (header, body, severity, time) => {
        let timeToExpire = 0;
        if (time === null) {
            /* Assumes someone reading at 150 words per minute, with some additional leeway. */
            timeToExpire = Math.ceil((((header?.split(" ").length ?? 0) + (body?.split(" ").length ?? 0)) / 150) * 60_000);

            if (timeToExpire < 2_000) timeToExpire = 3_000;
            if (timeToExpire < 5_000) timeToExpire += 1_000;
        } else {
            timeToExpire = time * 1_000;
        }

        /* Ensures a new toast never expires before an older one. */
        if (this.#toasts.length > 0) {
            let lastExpiration = this.#toasts[this.#toasts.length - 1].time;
            timeToExpire += lastExpiration;
        } else {
            timeToExpire += Date.now();
        }

        let previousToast = this.#toasts?.[this.#toasts?.length - 1];
        let toast = {
            index: previousToast ? previousToast.index + 1 : 1,
            id: uuid(),
            header: header,
            body: body,
            severity: severity,
            time: timeToExpire,
        };
        this.#toasts.push(toast);
        this.#create(toast);

        /* Only initiates one interval. */
        if (!this.#interval) {
            this.#loop();
        }
    }

    /* Responsible for creating the toast element. */
    static #create = (toast) => {
        try {
            let element = document.createElement("div");
            element.id = toast.id;
            element.setAttribute("data-expire", toast.time);
            element.classList.add(`toast__container`);
            element.classList.add(`toast__animation--slide-in`);
            element.classList.add(`toast__level--${toast.severity.toLowerCase()}`);
            element.style.zIndex = toast.index;
            this.#root().appendChild(element)

            let close_el = document.createElement("div");
            close_el.classList.add(`toast__close`);
            close_el.onclick = this.#remove;
            element.appendChild(close_el);

            if (toast?.header?.length > 0) {
                let header_el = document.createElement("div");
                header_el.classList.add(`toast__header`);
                header_el.innerText += toast.header;

                if ((toast?.body?.length ?? 0) === 0) {
                    header_el.style.margin = "0px";
                }

                element.appendChild(header_el);
            }

            if (toast?.body?.length > 0) {
                let body_el = document.createElement("div");
                body_el.classList.add(`toast__body`);
                body_el.innerText += toast.body;
                element.appendChild(body_el);
            }
        } catch (e) {
            console.warn(e);
        }
    }

    /* Manually remove. */
    static #remove = (event) => {
        event.preventDefault();
        let parent = event.target?.parentElement;
        let uuid = event.target?.parentElement?.id;
        if (!uuid) return;

        let toast_index = this.#toasts.findIndex(item => item.id === uuid);
        if (toast_index >= 0) {
            let toast = this.#toasts.splice(toast_index, 1)[0];
            this.#delete(toast);
        }
    }

    /* Gracefully delete. */
    static #delete = (toast) => {
        let element = document.getElementById(toast.id);
        if (!element) return;

        element.classList.add("toast__animation--slide-out")
        element.style.marginBottom = `-${element.clientHeight + 16}px`;
        setTimeout(() => {
            this.#destroy(toast);
        }, 500);
    }

    /* Attempts to destroy a toast element. */
    static #destroy = (toast) => {
        let element = document.getElementById(toast.id);
        if (!element) return;
        element.remove();
    }

    /* Loops over all cached toasts and destroys them as they reach their lifespan. */
    static #loop = async () => {
        this.#interval = setInterval(() => {
            let toast = null;
            if ((this.#toasts[0]?.time ?? 0) <= Date.now()) {
                toast = this.#toasts.splice(0, 1)[0];
            }

            if (toast) {
                this.#delete(toast);
            }

            if (this.#toasts.length === 0) {
                clearInterval(this.#interval);
                this.#interval = null;
            }
        }, 500);

        let trim_interval = null;
        trim_interval = setInterval(() => {
            let toast = null;
            if (this.#toasts.length > Toast.MAX_TOASTS) {
                toast = this.#toasts.splice(0, 1)[0];
            }

            if (toast) {
                this.#delete(toast);
            }

            if (this.#toasts.length === 0) {
                clearInterval(trim_interval);
            }
        }, 50);
    }

    /* Returns the toast root object, or creates one if none exist. */
    static #root = () => {
        if (this.#rootItem) return this.#rootItem;

        let element = document.getElementById("toast__root");
        if (!element) {
            element = document.createElement("div");
            document.body.appendChild(element);
            element.id = "toast__root";
            element.classList.add("toast__root")
        }

        this.#rootItem = element;
        return element;
    }
}

export default Toast;
