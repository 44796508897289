import React, { useContext, useEffect, useMemo } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import { pageTitle } from "@/res/helpers/PageHelper";
import useTextfieldInput from "@/res/hooks/Form/useTextfieldInput";
import { useTranslation } from "react-i18next";
import useDateInput from "@/res/hooks/Form/useDateInput";
import useSelectionInput from "@/res/hooks/Form/useSelectionInput";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import useNumberInput from "@/res/hooks/Form/useNumberInput";
import useStringInput from "@/res/hooks/Form/useStringInput";
import useEmailInput from "@/res/hooks/Form/useEmailInput";
import usePhoneInput from "@/res/hooks/Form/usePhoneInput";
import useHiddenInput from "@/res/hooks/Form/useHiddenInput";
import Services from "@/res/components/ServicePages/ServiceSpecific/acno/components/services";

const CLIENT_TYPES = [
    {
        label: "Velg en kundetype",
        value: "",
    },
    {
        label: "Privatperson",
        value: "private",
        internal: "private",
    },
    {
        label: "Holdingselskap",
        value: "holding",
        internal: "holding",
    },
    {
        label: "Aksjeselskap",
        value: "company",
        internal: "company",
    },
    {
        label: "Enkeltpersonforetak",
        value: "soleproprietorship",
        internal: "soleproprietorship",
    },
]

const Sales = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    // Miscellaneous
    const fieldInput = useTextfieldInput({
        label: props?.fieldLabel ?? t("pages.customise.2-service.basics.describe-needs-label"),
        key: "config.help_description", context: serviceContext, rule: {
            maxLength: 5000, required: false,
        },
    });

    const selectionInput = useSelectionInput({
        label: "Hvilket regnskapssystem bruker / planlegger kunden å bruke?", options: props?.softwares(t),
        rule: { required: true },
        context: serviceContext, key: "config.software",
    });

    const clientTypeInput = useSelectionInput({
        context: serviceContext, key: "config.funnel.client", class: "small",
        label: "Hvilken type kunde er dette?",
        options: CLIENT_TYPES, rule: { required: true },
    });

    const funnelType = useHiddenInput({
        context: serviceContext, key: "config.funnel.type",
    });

    const clientType = useHiddenInput({
        context: serviceContext, key: "config.funnel.client_type",
    });
    useEffect(() => {
        if (clientTypeInput?.value?.index) {
            clientType.setValue(CLIENT_TYPES[Number(clientTypeInput.value.index)].internal)
        } else {
            let index = -1;
            switch (serviceContext?.state?.client?.company?.orgType) {
                case "AS":
                    index = CLIENT_TYPES.findIndex(item => item.internal === "company")
                    break;
            }
            if (index >= 0) {
                clientTypeInput.setValue({
                    index,
                    label: CLIENT_TYPES[index].label,
                });
            }
        }
    }, [clientTypeInput.value])

    useEffect(() => {
        funnelType.setValue("UMBRACO");
    }, [])

    // Payroll
    const payrollDate = useNumberInput({
        rule: { required: false, minValue: 1, maxValue: 28 },
        context: serviceContext, key: "config.services.payroll.date",
        class: "small", placeholder: "Etter avtale",
    });

    // GST
    const gstCadence = useHiddenInput({
        context: serviceContext, key: "config.services.gst-registered.cadence",
        default: "quarterly",
    })

    // Former Accountant/Auditor
    const accountantExistsInput = useToggleInput({
        context: serviceContext, key: "config.previous_accountant.exists",
        label: t("pages.customise.2-service.former-accountant-auditor.toggle-accountant"),
        rule: { hideInput: true },
    });
    const accountantNameInput = useStringInput({
        context: serviceContext, key: "config.previous_accountant.name", class: "small",
        label: t("_shared.form.name-label"), placeholder: t("_shared.form.name-placeholder"),
        rule: { required: false },
    });
    const accountantEmailInput = useEmailInput({
        context: serviceContext, key: "config.previous_accountant.email", class: "small",
        label: t("_shared.form.email-label"), placeholder: t("_shared.form.email-placeholder"),
        rule: { required: false },
    });
    const accountantPhoneInput = usePhoneInput({
        context: serviceContext, key: "config.previous_accountant.phone_number", class: "small",
        label: t("_shared.form.phone-label"),
        rule: { required: false },
    });

    const auditorExistsInput = useToggleInput({
        context: serviceContext, key: "config.previous_auditor.exists",
        label: t("pages.customise.2-service.former-accountant-auditor.toggle-auditor"),
        rule: { hideInput: true },
    });
    const auditorNameInput = useStringInput({
        context: serviceContext, key: "config.previous_auditor.name", class: "small",
        label: t("_shared.form.name-label"), placeholder: t("_shared.form.name-placeholder"),
        rule: { required: false },
    });
    const auditorEmailInput = useEmailInput({
        context: serviceContext, key: "config.previous_auditor.email", class: "small",
        label: t("_shared.form.email-label"), placeholder: t("_shared.form.email-placeholder"),
        rule: { required: false },
    });
    const auditorPhoneInput = usePhoneInput({
        context: serviceContext, key: "config.previous_auditor.phone_number", class: "small",
        label: t("_shared.form.phone-label"),
        rule: { required: false },
    });

    return (
        <>
            { pageTitle("Sales") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.basics.header-miscellaneous")}
                                </h5>
                                {clientTypeInput.render()}
                                <br/>
                                {selectionInput.render()}
                                <br/>
                                {fieldInput.render()}
                                <div className="input-attachment">
                                    <small>
                                        Overskrider du 1200 tegn er det mulig at det sendes inn til TMS som et dokument istedenfor som notes.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    Tjenester
                                </h5>

                                <Services
                                    label="Bokføring"
                                    value="bookkeeping"
                                    path="config.services.bookkeeping"
                                    type="date"
                                />

                                <Services
                                    label="Bankavstemming"
                                    value="bank reconciliation inc vat"
                                    path="config.services.bank-reconciliation"
                                    type="date"
                                />

                                <Services
                                    label="Lønnsarbeid"
                                    value="salary"
                                    path="config.services.payroll"
                                >
                                    <label style={{ fontSize: "1rem" }}>
                                        Hvilken dag i måneden skal det utbetales lønn?
                                    </label>
                                    {payrollDate.render()}
                                    <div className="input-attachment">
                                        <small style={{ fontSize: "14px" }}>
                                            Velg mellom den 1. og 28. i måneden, all annen verdi blir oppført som &quot;Etter avtale&quot;
                                        </small>
                                    </div>
                                </Services>

                                <Services
                                    label="Merverdiavgift"
                                    value="gst registered"
                                    path="config.services.gst-registered"
                                    type="date"
                                >
                                    <div className="button-group button-group-left">
                                        <div
                                            className={ ["button__pill", gstCadence.value === "quarterly" ? "button__pill--selected" : ""].join(" ") }
                                            onClick={() => gstCadence.setValue("quarterly")}
                                        >
                                            Termin
                                        </div>
                                        <div
                                            className={ ["button__pill", gstCadence.value === "yearly" ? "button__pill--selected" : ""].join(" ") }
                                            onClick={() => gstCadence.setValue("yearly")}
                                        >
                                            Årlig
                                        </div>
                                    </div>
                                </Services>

                                { ![1, 4].includes(Number(clientTypeInput.value?.index)) &&
                                    <Services
                                        label="Aksjonærregisteroppgave"
                                        value="shareholder register"
                                        path="config.services.shareholder-register"
                                        type="year"
                                    />
                                }

                                <Services
                                    label="Årsoppgjør"
                                    value="annual settlement"
                                    path="config.services.annual-settlement"
                                    type="year"
                                />

                                <Services
                                    label="Klargjøring til årsoppgjør"
                                    value="preparation for end of year"
                                    path="config.services.end-of-year-preparation"
                                    type="year"
                                />

                                <Services
                                    label="Klargjøring til årsoppgjør (for dem med under 100 bilag per år)"
                                    value="preparation for holding"
                                    path="config.services.holding-preparation"
                                    type="year"
                                />

                                <hr className="my-4" style={{ border: "1px solid #d7dfe9" }}/>

                                <Services
                                    label="Ad-hoc hjelp"
                                    value="ad-hoc guidance"
                                    path="config.services.adhoc-guidance"
                                />

                                <Services
                                    label="Prosjektregnskap (Innovasjon Norge)"
                                    value="control of project accounting / innovation norway"
                                    path="config.services.control-project-accounting"
                                />

                                <Services
                                    label="Bekreftelse av aksjeinnskudd"
                                    value="confirmation of stock deposit"
                                    path="config.services.stock-deposit-confirmation"
                                />

                                <Services
                                    label="Klargjøring til avvikling"
                                    value="preparation for liquidation"
                                    path="config.services.liquidation-preparation"
                                />

                                <Services
                                    label="Avviking av virksomhet"
                                    value="liquidation"
                                    path="config.services.liquidation"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" style={{ zIndex: "1", marginBottom: "10rem" }}>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.former-accountant-auditor.header")}
                                </h5>

                                <div className="input-attachment">
                                    <small>
                                        {t("pages.customise.2-service.former-accountant-auditor.disclaimer")}
                                    </small>
                                </div>

                                <div className={ "inner-container-header" + (accountantExistsInput.value ? " show" : "")}>
                                    {accountantExistsInput.render()}
                                </div>
                                <div className={ "inner-container inner-container-attached" + (accountantExistsInput.value ? " show" : "")}>
                                    { accountantNameInput.render() }
                                    <div className="input-group always">
                                        { accountantEmailInput.render() }
                                        { accountantPhoneInput.render() }
                                    </div>
                                </div>

                                <div className={ "inner-container-header" + (auditorExistsInput.value ? " show" : "")}>
                                    {auditorExistsInput.render()}
                                </div>
                                <div className={ "inner-container inner-container-attached" + (auditorExistsInput.value ? " show" : "")}>
                                    { auditorNameInput.render() }
                                    <div className="input-group always">
                                        { auditorEmailInput.render() }
                                        { auditorPhoneInput.render() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sales;
