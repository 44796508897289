import React, { useEffect } from "react";
import useForm from "@/res/hooks/Form/useForm";
import { resolvePath } from "@/res/helpers/ObjectHelpers";
import { useTranslation } from "react-i18next";
import { IS_SERVER } from "@/res/helpers/ServiceHelper";

const useSelectionInput = (props) => {
    const [t] = useTranslation();

    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "selectionInput";
    const elementId = `useSelectionInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePath(context?.state, key) : values[key];
    const options = props?.options ?? [];

    useEffect(() => {
        if (IS_SERVER) return;

        isValid();
    }, [])

    const setValue = (value) => {
        forceValue(key, value);
    };

    const reset = () => {
        forceValue(key, props?.default ?? null);
    }

    const isValid = (v = null) => {
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;
        let msg = "";

        if ((rule?.required ?? true) && !(_value?.index >= 1)) {
            item?.setCustomValidity?.(t("_shared.error.selection-required"));
            return false;
        }

        item?.setCustomValidity?.("");
        return true;
    }

    const handleChangeMiddleman = (e) => {
        setValue({
            index: e.target.value,
            label: options[e.target.value]?.label,
            id: options[e.target.value]?.internal_id,
        });
        isValid({ index: e.target.value });
    }

    const render = () => {
        return (
            <div>
                { props?.label &&
                <label htmlFor={ elementId }>
                    { props?.label }
                </label>
                }
                <select
                    id={elementId}
                    name={key}
                    placeholder={props?.placeholder ?? ""}
                    value={value?.index ?? ""}
                    onChange={ props?.onChange ?? handleChangeMiddleman}
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    required={rule?.required ?? true}
                    className={props?.class}
                >
                    { options?.map?.((item, index) => {
                        return (
                            <option key={"option" + index} value={index > 0 ? index : ""}>
                                {item?.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        )
    }

    return {
        value, render, reset, isValid, setValue,
    };
};

export default useSelectionInput;
