import React, { useEffect } from "react";
import useForm from "@/res/hooks/Form/useForm";
import { resolvePath } from "@/res/helpers/ObjectHelpers";

const useDateInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "dateInput";
    const elementId = `useDateInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePath(context?.state, key) : values[key];

    useEffect(() => {
        if (value == null || value?.length <= 0) {
            if (rule?.prefill) {
                setValue(new Date().toISOString().slice(0, 10))
            } else if (rule?.minValue) {
                setValue(rule?.minValue);
            }
        }

        isValid();
    }, []);

    const handleChangeMiddleman = (e) => {
        handleChange(e);
        isValid(e?.target?.value);
    };

    const setValue = (value) => {
        forceValue(key, value);
    };

    const isValid = (v = null) => {
        let item = document.getElementById(elementId);

        item?.setCustomValidity?.("");
        return true;
    }

    const render = () => {
        return (
            <div>
                { props?.label &&
                <label htmlFor={ elementId }>
                    {props?.label}
                </label>
                }
                <input
                    type="date"
                    id={ elementId }
                    name={ key }
                    value={ value }
                    placeholder={ props?.placeholder ?? "dd/mm/yyyy" }
                    onChange={ props?.onChange ?? handleChangeMiddleman }
                    min={ rule?.minValue } // "2020-01-01"
                    max={ rule?.maxValue } // "2021-12-31"
                    required={ rule?.required ?? true }
                    className={ props?.class }
                    disabled={ rule?.disabled ?? false }
                />
            </div>
        );
    };

    return {
        value, render, isValid, setValue,
    };
};

export default useDateInput;
