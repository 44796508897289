import React, { useContext } from "react";
import useTextfieldInput from "@/res/hooks/Form/useTextfieldInput";
import useDateInput from "@/res/hooks/Form/useDateInput";
import useSelectionInput from "@/res/hooks/Form/useSelectionInput";
import { pageTitle } from "@/res/helpers/PageHelper";
import { useTranslation } from "react-i18next";
import { ServiceContext } from "@/res/context/ServiceContext";

const Basics = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    const fieldInput = useTextfieldInput({
        label: props?.fieldLabel ?? t("pages.customise.2-service.basics.describe-needs-label"),
        key: "config.help_description", context: serviceContext, rule: {
            maxLength: 500, required: false,
        },
    });

    const dateInput = useDateInput({
        label: props?.dateLabel ?? t("pages.customise.2-service.basics.acno-starting-date-label"),
        context: serviceContext, key: "config.start_date", rule: {
            prefill: true, minValue: (serviceContext?.state?.client?.is_sales ? null : "2023-01-01"), required: true,
        },
    });

    const selectionInput = useSelectionInput({
        label: props?.optionsLabel ?? t("pages.customise.2-service.basics.accounting-software-label"), options: props?.softwares(t),
        rule: { required: true },
        context: serviceContext, key: "config.software",
    });

    return (
        <>
            { pageTitle("The basics") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.basics.header-miscellaneous")}
                                </h5>
                                {dateInput.render()}
                                <div className="input-attachment">
                                    <small>
                                        {t("pages.customise.2-service.basics.acno-starting-date-info")}
                                    </small>
                                </div>
                                <br/>
                                {selectionInput.render()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.basics.header-description")}
                                </h5>
                                {fieldInput.render()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Basics;
