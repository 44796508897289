import React, { useContext, useEffect, useState } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import useHiddenInput from "@/res/hooks/Form/useHiddenInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const ServiceSelection = (props) => {
    const serviceContext = useContext(ServiceContext);
    const data = (typeof props?.services) === "function" ? props?.services?.() : props?.services;
    const [validServices, setValidService] = useState();

    const funnelType = useHiddenInput({
        context: serviceContext, key: "config.funnel.type",
    });

    const funnelServices = useHiddenInput({
        context: serviceContext, key: "config.funnel.subservices",
    });

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
        let index = -1;

        let services = {};
        data?.forEach((item, i) => {
            let valid = true;

            if ((item?.for_business ?? true) !== serviceContext?.state?.client?.is_business) {
                valid = false;
            }

            if ((item?.for_subscriptions ?? false) !== (serviceContext?.state?.pricing?.subscription_index >= 0 ?? false)) {
                valid = false;
            }

            if (valid) {
                index = i;
                services[i] = item;
            }
        });
        setValidService(services);

        funnelType.setValue(null);
        funnelServices.setValue(null);

        // The following is checking whether or not we should skip the page.
        let validCategories = Object.keys(services ?? {})?.filter?.((serviceIndex, i) => {
            let item = services[serviceIndex];

            if (serviceContext?.state?.pricing?.compatibleCategories?.includes(item?.internal_id)) {
                index = i;
                return serviceContext?.state?.pricing?.compatibleCategories?.includes(item?.internal_id)
            }
        });

        if (validCategories?.length === 1 || Object.keys(services ?? {})?.length === 1) {
            if (serviceContext?.state?.movement > 0) {
                selectService(index)
            } else {
                serviceContext?.dispatch?.({ type: serviceContext?.actions?.PAGE_RETURN });
            }
        }
    }, [])

    const evaluate = () => {
        return (data?.length ?? 0) > 0;
    };

    const hiddenInput = useHiddenInput({ key: "category", context: serviceContext });
    const selectService = index => {
        hiddenInput.setValue({
            index: index,
            id: data?.[index]?.internal_id,
            name: data?.[index]?.name,
            has_contract: data?.[index]?.has_contract ?? true,
        });

        serviceContext?.dispatch?.({ type: serviceContext?.actions?.PAGE_NEXT, force: true });
    };

    if ((Object.keys(validServices ?? {})?.length ?? 0) === 0) {
        return (
            <>
                { pageTitle("No available service") }

                <div className="container funnel-container funnel-container-bottom-buffer">
                    <div className="row justify-content-center">
                        <div className="funnel-big-card no-click" style={{ borderColor: "#282828" }}>
                            <div className="service-card-header">
                                Ingen tilgjengelige tjenester
                            </div>

                            <div className="service-card-description no-click">
                                <p>
                                    Det er ingen tilgjengelige tjenester å tilby. Dersom du er en kunde og det skulle vært en
                                    tjeneste her så vennligst informer kundeservice at noe er gale.
                                </p>
                                <p>
                                    Oppgi denne koden: { `#${serviceContext?.state?.shared?.service_code}-2000` }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            { pageTitle("Select service") }

            <div className="container funnel-container funnel-container-bottom-buffer">
                <div className="row justify-content-center">
                    <div className="col-12">
                        { Object.keys(validServices ?? {})?.map((serviceIndex, index) => {
                            let item = validServices[serviceIndex];
                            let is_sales_only = item?.sales_only === true;

                            if (!serviceContext?.state?.client?.is_sales && is_sales_only) {
                                return;
                            }

                            if (serviceContext?.state?.pricing?.compatibleCategories?.length > 0) {
                                if (!serviceContext?.state?.pricing?.compatibleCategories?.includes(item?.internal_id)) {
                                    return null;
                                }
                            }

                            return (
                                <div key={index} className="service-card" style={{ borderColor: `${item?.color}` }} onClick={() => selectService(serviceIndex)}>
                                    <div className={ "service-card-header no-image" + (is_sales_only ? " service-card-sales" : "") }>
                                        {item?.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceSelection;
