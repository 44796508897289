import React, { useContext } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import { pageTitle } from "@/res/helpers/PageHelper";
import useToggleInput from "@/res/hooks/Form/useToggleInput";

const AnnualReportAccounting = (props) => {
    const serviceContext = useContext(ServiceContext);
    const { state } = serviceContext;

    const annualReportingToggle = useToggleInput({
        context: serviceContext, key: "config.annual_reporting",
        label: "Skal årsoppgjøret utføres av Synega?", default: true,
        rule: { hideInput: true },
    });

    return (
        <>
            { pageTitle("Annual reporting") }

            <div className="funnel-container funnel-container-bottom-buffer">
                { state?.client?.is_sales &&
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="funnel-default-card card--sales">
                                    {annualReportingToggle.render()}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    Årlig rapportering til myndighetene
                                </h5>
                                <p>
                                    Utover det du gjør selv må disse oppgavene gjøres av oss dersom vi skal stå oppført som din
                                    regnskapsfører. Ellers må du heller velge «Enkeltimer».
                                </p>

                                <div className="toggle-container">
                                    <label>
                                        Skattemelding
                                    </label>
                                    <div className="toggle-button locked">
                                        <span/>
                                    </div>
                                </div>
                                <div>
                                    <small>
                                        Skattemeldingen må sendes inn hvert år før den 31. mai, uavhengig av aktiviteten i
                                        bedriften. Skattemeldingen er en oppsummering av {serviceContext?.company?.name} sitt
                                        resultat foregående år og den legger grunnlaget for hva selskapet skal betale i skatt.
                                    </small>
                                </div>

                                <br/>

                                <div className="toggle-container">
                                    <label>
                                        Årsregnskap
                                    </label>
                                    <div className="toggle-button locked">
                                        <span/>
                                    </div>
                                </div>
                                <div>
                                    <small>
                                        Årsregnskapet må sendes inn innen 31. juli. hvert år og dette viser bedriftens økonomiske aktiviteter for året som har gått.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnnualReportAccounting;
