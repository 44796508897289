import React from "react";
import DefaultHead from "@/components/defaults/head";
import { GenericWrapper } from "@/res/context/generic";
import ClientNav from "@/components/client/nav";
import ClientFooter from "@/components/client/footer";

const DefaultComponentWrapper = ({ Component, pageProps }) => {
    return (
        <div id="__default">
            {/*<I18nProvider i18n={i18n}>*/}
            <DefaultHead/>
            <GenericWrapper storageKey="client">
                <ClientNav {...pageProps}/>
                <Component {...pageProps}/>
                <ClientFooter/>
            </GenericWrapper>
            {/*</I18nProvider>*/}
        </div>
    );
};

export default DefaultComponentWrapper;
