import React from "react";
import TooltipSVG from "@/assets/icons-js/tooltip";

const Tooltip = ({ children, className }) => {
    const click = (e) => {
        e?.preventDefault();
        if (e.target.classList.contains("tooltip")) {
            console.log("hi")
            e.target.classList.toggle("tooltip--active")
        }
    }

    return (
        <div className={ ["tooltip", className ?? ""].join(" ") } onClick={click}>
            <TooltipSVG/>
            <div>
                { children }
            </div>
        </div>
    );
};

export default Tooltip;
