import React from "react";
import DescribeHelp from "@/res/components/ServicePages/DescribeHelp";
import AcceptTerms from "@/res/components/ServicePages/AcceptTerms";

const priceData = (props) => [
    {
        type: "PAYG",
        name: "Minutengenaue Abrechnung",
        details: [{
            title: "199 € pro Stunde",
            description:
                <>
                    <p>
                        Mit unserer minutengenauen Abrechnung bezahlst Du nur die wirklich angefallene Zeit - und
                        nicht angebrochene größere Zeitabschnitte.
                    </p>
                </>,
        }],
    },
    {
        type: "FIXED",
        name: "Pauschalhonorar",
        details: [{
            title: "nach Vereinbarung",
            description:
                <>
                    <p>
                        Fordere unverbindlich ein Angebot an, über das Du entscheiden kannst, bevor es losgeht.
                    </p>
                </>,
        }],
    },
    {
        type: "SUBSCRIPTION",
        name: "Abonnement",
        for_business: true,
        details: [
            {
                title: "Paket S",
                description:
                    <>
                        <p>
                            10 Stunden pro Monat: 1.749 €
                        </p>
                    </>,
            },
            {
                title: "Paket M",
                description:
                    <>
                        <p>
                            30 Stunden pro Monat: 5.199 €
                        </p>
                    </>,
            },
            {
                title: "Paket L",
                description:
                    <>
                        <p>
                            70 Stunden pro Monat: 11.999 €
                        </p>
                    </>,
            },
            {
                title: "Nicht sicher?",
                description:
                    <>
                        <p>
                            Weißt Du noch nicht, wie groß Dein Bedarf ist?
                        </p>
                    </>,
            },
        ],
    },
];

const categoryData = [
    {
        internal_id: 1,
        name: "Arbeitsrecht",
        color: "#d898c6",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 2,
        name: "Marken und Designs",
        color: "#4495a7",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 3,
        name: "Vertragsrecht",
        color: "#f2af89",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 4,
        name: "Gesellschaftsrecht",
        color: "#3d78a4",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 5,
        name: "Datenschutzrecht (DSGVO)",
        color: "#58caa1",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 6,
        name: "Marketing",
        color: "#7e7dca",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },

    {
        internal_id: 4000,
        name: "Business-Abo",
        color: "#282828",
        for_business: true,
        for_subscriptions: true,
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },

    {
        internal_id: 5000,
        name: "Privatperson",
        color: "#282828",
        for_business: false,
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring that they accept our privacy terms.";
                    return <AcceptTerms {...props}/>
                },
            ],
        ],
    },
];

const Lede = {
    props: () => {
        return {
            prices: priceData,
            services: categoryData,
        };
    },
}

export default Lede;
