import React, { useEffect, useState } from "react";
import ChevronDownSVG from "@/assets/icons-js/chevron-down";
import useRouter from "@/res/components/router";

const NavDropdown = ({ skey, label, logoComponent, children, ...rest }) => {
    const [open, setOpen] = useState(false);
    const router = useRouter();

    const toggle = (e) => {
        setOpen(!open)
    }

    useEffect(() => {
        if (!children) return;
        if (rest?.default === "open") {
            setOpen(true);
            return;
        }

        if (typeof children[Symbol.iterator] === "function") {
            for (let child of children) {
                const _href = child?.props?.href;
                if (!_href) continue;

                if (router.asPath?.split("?")?.[0] === _href || router.asPath?.split("?")?.[0] === `/${ router.locale }${ _href }/`) {
                    setOpen(true);
                    break;
                }
            }
        } else {
            const _href = children?.props?.href;
            if (!_href) return;

            if (router.asPath?.split("?")?.[0] === _href || router.asPath?.split("?")?.[0] === `/${ router.locale }${ _href }/`) {
                setOpen(true);
            }
        }
    }, [])

    return (
        <div onClick={toggle}>
            <div className={ ["nav__dropdown", open ? "open" : ""].join(" ") }>
                <span>{logoComponent}</span>
                <span>
                    {label}
                </span>
                <span><ChevronDownSVG/></span>
            </div>
            <div className={ ["collapsable nav__dropdown-content", open ? "open" : ""].join(" ") }>
                <div className={ ["collapsable__body", open ? "open" : ""].join(" ") }>
                    <div>
                        <div className="nav__links py-1">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavDropdown;
