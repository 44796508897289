import React from "react";

const useProps = (params) => {
    return {
        data: params?.data ?? {},
        defaultProps: params?.default ?
            Object.keys(params?.default)
                .filter(key => params.default[key] != null && (params.default[key].toString()).length > 0)
                .reduce((result, current) => {
                    result[current] = params.default[current];
                    return result;
                }, {})
            : {},
        props: Object.keys(params ?? {})
            .filter(key => !["default", "data"].includes(key))
            .reduce((result, current) => {
                result[current] = params[current];
                return result;
            }, {}),
    }
};

export default useProps;
