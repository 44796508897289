import Head from "next/head";
import React from "react";

export const pageTitle = (title) => {
    return (
        <Head>
            <title>{title} | Synega</title>
            <meta property="og:title" content={ title + " | Synega" }/>
            <meta name="twitter:title" content={ title + " | Synega" }/>
        </Head>
    );
}
