import React, { useContext } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useSelectionInput from "@/res/hooks/Form/useSelectionInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const AssistanceCategory = (props) => {
    const serviceContext = useContext(ServiceContext);

    const selectionInput = useSelectionInput({
        options: props?.options, rule: { required: true },
        context: serviceContext, key: `config.assistance_category${ props?.saveAs ? ("." + props?.saveAs) : ""}`,
    })

    return (
        <>
            { pageTitle("Categorise the assistance needed") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    Hva slags bistand ønskes?
                                </h5>
                                {selectionInput.render()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssistanceCategory;
