import React, { useContext, useMemo, useReducer } from "react";
import formReducer, { FormReducerLite, ACTIONS } from "@/res/reducers/ServiceReducer";
import { initialiseFormPayload } from "@/res/helpers/ServiceHelper";

export const ServiceContext = React.createContext();

export const ServiceWrapper = (props) => {
    const [state, dispatch] = useReducer(props?.lite ? FormReducerLite : formReducer, initialiseFormPayload({ lite: props?.lite ?? false, initial: props?.initial ?? false }))

    const contextValue = useMemo(() => {
        if (props?.contextRef != null) {
            props.contextRef.current = { state, dispatch };
        }

        return {
            state: state,
            dispatch: dispatch,
            actions: ACTIONS,
            defaultAction: ACTIONS.SAVE,
        };
    }, [state, dispatch]);

    return (
        <ServiceContext.Provider value={contextValue}>
            { props?.children }
        </ServiceContext.Provider>
    );
}

export function useServiceContext() {
    return useContext(ServiceContext);
}
