import React, { useEffect, useState } from "react";
import useForm from "@/res/hooks/Form/useForm";
import { resolvePath } from "@/res/helpers/ObjectHelpers";
import { useTranslation } from "react-i18next";
import { IS_SERVER } from "@/res/helpers/ServiceHelper";

const useToggleInput = (props) => {
    const [t] = useTranslation();
    const { values, forceValue, resetValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "toggleInput";
    const elementId = `useToggleInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePath(context?.state, key) : values[key];
    const classNames = (props?.class && `${props?.class} `) ?? (props?.className && `${props?.className} `) ?? "";

    useEffect(() => {
        if ((value === "" || value == null) && (props?.default !== undefined || rule?.default !== undefined)) {
            setValue(props?.default ?? rule?.default ?? false);
        } else {
            isValid(value);
        }
    }, [])

    const isValid = (v = null) => {
        if (IS_SERVER) return true;
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;

        if (rule?.required) {
            if (rule?.literals && (_value === null || _value === undefined)) {
                item?.setCustomValidity?.(t("_shared.form.required-option"));
                return false;
            } else if (!rule?.literals && !_value) {
                item?.setCustomValidity?.(t("_shared.form.required-choice"));
                return false;
            }
        }

        item?.setCustomValidity?.("");
        return true;
    };

    const setValue = (value) => {
        forceValue(key, value);
        isValid(value);
    };

    const reset = () => {
        forceValue(key, props?.default ?? false);
        isValid(props?.default ?? false);
    }

    const toggle = (e) => {
        if (rule?.disabled) return;
        setValue(value == null ? true : !value)
    };

    const render = () => {
        if (rule?.version === 2) {
            return (
                <>
                    <div className="toggle__container toggle__container--space-between">
                        <label>
                            {props?.label}
                        </label>
                        <div
                            className={ [
                                "toggle__button--slider",
                                (rule?.literals?.length > 1 ? "toggle__button--slider-dynamic" : ""),
                                (value === true ? "enabled" : ""),
                                (rule?.disabled ? "disabled" : ""),
                            ].join(" ") }
                            onClick={(e) => toggle(e)}
                            data-content={rule?.literals?.[0]?.label ?? rule?.literals?.[0]}
                        >
                            <div>
                                { rule?.literals?.[0]?.label ?? rule?.literals?.[0] ?? "Ja" }
                            </div>
                            <div>
                                { rule?.literals?.[1]?.label ?? rule?.literals?.[1] ?? "Nei" }
                            </div>
                        </div>
                    </div>
                    <input id={elementId} className="input__faux input__faux--relative"/>
                </>
            );
        }

        return (
            <>
                <div className={ classNames + "toggle-container cursor-pointer" +
                    (rule?.literals?.length > 1 || rule?.literals === true ? " toggle-literal" : "")
                } onClick={(e) => toggle(e)}>
                    { props?.label &&
                        <label>
                            {props?.label}
                        </label>
                    }
                    { (rule?.literals?.length > 1 || rule?.literals === true) ?
                        <div className={ classNames + "toggle-literals" }>
                            <span className={ value === true ? "selected" : "" }>
                                {rule?.literals?.[0] ?? t("_shared.form.toggle-literal-yes")}
                            </span>/
                            <span className={ value === false ? "selected" : "" }>
                                {rule?.literals?.[1] ?? t("_shared.form.toggle-literal-no")}
                            </span>
                        </div>
                        :
                        <div className={ classNames + "toggle-button " + (value ? "selected " : "") }>
                            <span/>
                        </div>
                    }
                </div>
                { !rule?.hideInput && <input id={elementId} className="input__faux input__faux--relative"/> }
            </>
        );
    };

    return {
        value, render, reset, isValid, setValue,
    };
};

export default useToggleInput;
