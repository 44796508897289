import React, { useEffect } from "react";
import useForm from "./useForm";
import { resolvePath } from "../../helpers/ObjectHelpers";

const useEmailInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "email";
    const elementId = `useEmailInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePath(context?.state, key) : values[key];

    // TODO temporary band-aid fix to placeholder attribute not updating itself based on translation string.
    useEffect(() => {
        let el = document.getElementById(elementId);
        el?.setAttribute?.("placeholder", props?.placeholder ?? "")
    }, []);

    const setValue = (value) => {
        forceValue(key, value);
    };

    const isValid = (v = null) => {
        let value = v != null ? v : value;
        if (typeof value === "object") {
            value = value.address;
        }

        let atOccurrences = value?.match(/[@]/g)?.join("");
        if (atOccurrences?.length > 1) return false;

        let atIndex = value?.indexOf("@");
        if (atIndex === -1) return false;
        if (atIndex === 0) return false;
        if (atIndex + 1 === value?.length) return false;

        let dotIndex = value?.lastIndexOf(".");
        if (dotIndex === -1) return false;
        if (dotIndex + 1 === value?.length) return false;
        if (dotIndex < atIndex) return false;

        return true;
    }

    const handleChangeMiddleman = (e) => {
        let target = e.target.value;
        if (value?.id != null || value?.address != null) {
            let target = value;
            target.address = e?.target?.value;
        }

        setValue(target);
        // isValid(e?.target?.value);
    };

    const displayValue = () => {
        if (value?.id != null || value?.address != null) {
            return value?.address ?? "";
        }

        return value ?? "";
    };

    const render = (inputOnly = false) => {
        if (inputOnly) {
            return (
                <input
                    id={elementId}
                    name={key}
                    type="email"
                    placeholder={props?.placeholder ?? "john@citizen.com"}
                    autoComplete={ rule?.autoComplete ?? "email" }
                    value={ displayValue() }
                    onChange={ props?.onChange ?? handleChangeMiddleman }
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    required={ rule?.required ?? true}
                    className={ props?.class }
                    disabled={ rule?.disabled ?? false }
                />
            );
        }

        return (
            <div>
                { props?.label &&
                <label htmlFor={elementId}>
                    {props?.label}
                </label>
                }
                <input
                    id={elementId}
                    name={key}
                    type="email"
                    placeholder={props?.placeholder ?? "john@citizen.com"}
                    autoComplete={ rule?.autoComplete ?? "email" }
                    value={ displayValue() }
                    onChange={ props?.onChange ?? handleChangeMiddleman }
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    required={ rule?.required ?? true}
                    className={ props?.class }
                    disabled={ rule?.disabled ?? false }
                />
            </div>
        );
    };

    return {
        value, render, setValue, isValid,
    };
};

export default useEmailInput;
