import React, { useContext } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useTextfieldInput from "@/res/hooks/Form/useTextfieldInput";
import { useTranslation } from "react-i18next";
import { pageTitle } from "@/res/helpers/PageHelper";

const DescribeHelp = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    const fieldInput = useTextfieldInput({
        label: (props?.label ? props?.label : t("pages.customise.2-service.describe-help.label")),
        key: "config.help_description", context: serviceContext, rule: {
            minLength: 15, maxLength: 500, required: props?.required ?? false,
        },
    });

    return (
        <>
            { pageTitle("How can we help") }

            <div className="container funnel-container funnel-container-bottom-buffer">
                <div className="row justify-content-center">
                    <div className="funnel-default-card">
                        <h5>
                            { props?.title != null ?
                                props?.title
                                :
                                t("pages.customise.2-service.describe-help.header")
                            }

                        </h5>
                        {fieldInput.render()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DescribeHelp;
