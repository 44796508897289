import React from "react";
import { GenericWrapper } from "@/res/context/generic";
import DefaultHead from "@/components/defaults/head";
import Nav from "@/res/components/nav";

const LegacyComponentWrapper = ({ Component, pageProps }) => {
    return (
        <div id="__legacy">
            {/*<I18nProvider i18n={i18n}>*/}
            <DefaultHead/>
            <div className="background-container">
                <div className="default-background">
                    <div/>
                </div>
            </div>
            <GenericWrapper behaviour="update" storage="local">
                <Nav/>
                <Component {...pageProps} />
            </GenericWrapper>
            {/*</I18nProvider>*/}
        </div>
    );
};

export default LegacyComponentWrapper;
