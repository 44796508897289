import React, { useContext, useEffect } from "react";
import useDateInput from "@/res/hooks/Form/useDateInput";
import { ServiceContext } from "@/res/context/ServiceContext";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import useNumberInput from "@/res/hooks/Form/useNumberInput";
import useHiddenInput from "@/res/hooks/Form/useHiddenInput";

const ServiceDate = (props) => {
    const serviceContext = useContext(ServiceContext);
    const { label, path, required } = props;

    const dateInput = useDateInput({
        context: serviceContext, key: `${path}.date`,
        label: "",
        rule: { prefill: required ?? true, required: required ?? true }, class: "small",
    });

    return (
        <>
            <label style={{ fontSize: "1rem" }}>
                { label ?? "Fra dato" }
            </label>
            {dateInput.render()}
        </>
    );
}

const ServiceYear = (props) => {
    const serviceContext = useContext(ServiceContext);
    const { label, path, required } = props;

    const dateInput = useNumberInput({
        context: serviceContext, key: `${path}.date`,
        label: "", class: "small",
        rule: { required: required ?? true, minValue: 1990, maxValue: 2040 },
    });
    useEffect(() => {
        if (dateInput.value == null) {
            dateInput.setValue((new Date()).getFullYear())
        }
    }, [])

    return (
        <>
            <label style={{ fontSize: "1rem" }}>
                { label ?? "Fra år" }
            </label>
            {dateInput.render()}
        </>
    );
}

const Services = (props) => {
    const serviceContext = useContext(ServiceContext);
    const { value, label, labelAlt, path, type, required } = props;

    // const funnelServices = useHiddenInput({
    //     context: serviceContext, key: "config.funnel.subservices",
    // });

    const selectionInput = useToggleInput({
        context: serviceContext, key: `${path}.enabled`,
        rule: { hideInput: true }, class: "tiny", default: false,
        label: label,
    })
    // useEffect(() => {
    //     let list = structuredClone(funnelServices.value ?? []);
    //     list = [...new Set(list ?? [])]
    //
    //     if (selectionInput.value) {
    //         list.push(value);
    //     } else {
    //         list.filter(item => item !== value)
    //     }
    //
    //     console.log(list)
    //     funnelServices.setValue(list, true);
    // }, [selectionInput.value]);

    return (
        <div>
            {selectionInput.render()}
            {selectionInput.value &&
                <>
                    { type === "year" && <ServiceYear path={path} label={labelAlt} required={required}/> }
                    { type === "date" && <ServiceDate path={path} label={labelAlt} required={required}/> }
                    { props?.children }
                </>
            }
            <div className="mb-3"/>
        </div>
    );
};

export default Services;
