import React, { useContext, useEffect, useRef, useState } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import { useTranslation } from "react-i18next";
import useHiddenInput from "@/res/hooks/Form/useHiddenInput";
import useStringInput from "@/res/hooks/Form/useStringInput";
import { BrregUrl } from "@/res/helpers/BrregHelper";
import axios from "axios";
import { pageTitle } from "@/res/helpers/PageHelper";

const SEARCH_LOCKOUT = 200;

const Counterparts = (props) => {
    const [t] = useTranslation();
    const serviceContext = useContext(ServiceContext);

    const [searching, setSearching] = useState(false);
    const [lookupFocus, setLookupFocus] = useState(false);
    const [lastClick, setLastClick] = useState(Date.now())
    const lastClickRef = useRef(lastClick)
    const lookupUrlRef = useRef("");
    const [companies, setCompanies] = useState(null);
    const [company, setCompany] = useState(null);

    const existsInput = useToggleInput({
        context: serviceContext, key: "config.counterpart.exists",
        label: "Eksisterer det en motpart?", rule: { literals: true },
    });

    const isBusinessInput = useHiddenInput({
        context: serviceContext, key: "config.counterpart.is_business", default: false,
    });

    const businessNameInput = useHiddenInput({
        context: serviceContext, key: "config.counterpart.business_name", default: null,
    });

    const businessNumberInput = useHiddenInput({
        context: serviceContext, key: "config.counterpart.business_number", default: null,
    });

    const nameInput = useStringInput({
        context: serviceContext, key: "config.counterpart.name",
        label: "Navn på motpart", placeholder: t("_shared.form.name-placeholder"), class: "medium",
        rules: { maxLength: 255, required: true },
    });

    const companyLookup = () => {
        let time = Date.now();
        setLastClick(time);
        lastClickRef.current = time;

        setTimeout(fetchCompanyLookup, SEARCH_LOCKOUT)
    };
    const businessInput = useStringInput({
        onKeyUp: companyLookup, onFocus: () => setLookupFocus(true),
        rule: { required: true, autoComplete: "off" }, class: "search-field",
    });

    useEffect(() => {
        if (businessNameInput?.value?.length > 0) {
            setCompany({
                businessName: businessNameInput?.value,
                businessNumber: businessNumberInput?.value,
            });
        }

        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, []);

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [isBusinessInput.value, businessNameInput.value, businessNumberInput.value, serviceContext?.state?.config])

    const evaluate = () => {
        if (isBusinessInput.value === true) {
            return businessNameInput?.value?.length > 0 && businessNumberInput?.value?.length > 0;
        }

        return true;
    };

    const fetchCompanyLookup = () => {
        let diff = Date.now() - lastClickRef.current;
        if (diff < SEARCH_LOCKOUT || (businessInput.value?.length ?? 0) < 3 || searching) {
            return;
        }

        let url = BrregUrl(businessInput.value);
        lookupUrlRef.current = url;

        axios({
            method: "GET",
            url: url,
        }).then(res => {
            if (res.status === 200) {
                let oldUrl = encodeURI(lookupUrlRef?.current?.split("?")?.[1]?.replace("navn=", "")?.replace("organisasjonsnummer=", ""));
                let resUrl = res.data?._links?.self?.href?.split("?")?.[1]?.replace("navn=", "")?.replace("organisasjonsnummer=", "");

                if (companies == null || oldUrl === resUrl) {
                    setCompanies(res.data?._embedded?.enheter ?? null);
                }
            }
        }).catch(err => {
            console.log("Fetch company error", err)
        });
    }

    const resetCompany = () => {
        setCompany(null);
        businessNameInput.setValue(null);
        businessNumberInput.setValue(null);
    };

    const selectCompany = (company) => {
        businessNameInput.setValue(company?.navn);
        businessNumberInput.setValue(company?.organisasjonsnummer);
        setLookupFocus(false);
        setCompany(company);
    }

    return (
        <>
            { pageTitle("Counterparts") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    Motpart
                                </h5>
                                {existsInput.render()}
                            </div>
                        </div>
                    </div>
                </div>

                { existsInput.value &&
                <div className="container" style={{ marginTop: "5rem" }}>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <div className="type-container type-container-toggleable">
                                    <div className="type-buttons">
                                        <span className="type-button-container">
                                            <div onClick={() => isBusinessInput.setValue(true)} className={ "type-button " + (isBusinessInput?.value ? "active" : "")}>
                                                {t("pages.start.form.modes.business")}
                                            </div>
                                            <div className="type-divider"/>
                                            <div onClick={() => isBusinessInput.setValue(false)} className={ "type-button " + (!isBusinessInput?.value ? "active" : "")}>
                                                {t("pages.start.form.modes.private")}
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                { isBusinessInput?.value && !company &&
                                <>
                                    {businessInput.render()}
                                    { lookupFocus && companies?.length > 0 &&
                                    <div className="search-container short">
                                        { companies?.map((company, index) => {
                                            return (
                                                <div key={ company?.organisasjonsnummer ?? index }>
                                                    <div className="search-item" onClick={ () => selectCompany(company) }>
                                                        <strong>{ company.navn }</strong><br/>
                                                        { company.organisasjonsnummer }
                                                    </div>
                                                </div>
                                            );
                                        }) }
                                    </div>
                                    }
                                </>
                                }

                                { isBusinessInput?.value && company &&
                                <>
                                    <div>
                                        <strong>{company?.navn ?? company?.businessName}</strong><br/>
                                        {company?.organisasjonsnummer ?? company?.businessNumber}<br/>
                                    </div>
                                    <div>
                                        <p style={{ margin: ".25rem 0 0", fontSize: "100%", cursor: "pointer", color: "#007BFFFF" }}>
                                            <span onClick={resetCompany}>Søk på nytt</span>
                                        </p>
                                    </div>
                                </>
                                }

                                { !isBusinessInput?.value &&
                                <>
                                    {nameInput.render()}
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </>
    );
};

export default Counterparts;
