import React, { } from "react";
import { useRouter as useNextRouter } from "next/router";

const useRouter = (props) => {
    const router = useNextRouter();

    return {
        ...router,
        locale: "no-no",
        locales: ["default", "no-no", "se-no", "en-no"],
    }
}

export default useRouter;
