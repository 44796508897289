import React from "react";
import DescribeHelp from "@/res/components/ServicePages/DescribeHelp";
import AcceptTerms from "@/res/components/ServicePages/AcceptTerms";
import Associates from "@/res/components/ServicePages/Associates";
import Counterparts from "@/res/components/ServicePages/CountrySpecific/Norway/Counterparts";
import AcceptPrivacyPolicy from "@/res/components/ServicePages/AcceptPrivacyPolicy";

const priceData = (props) => [
    {
        type: "PAYG",
        name: "Betal per minutt",
        details: [{
            title: "Fra 1.820,- per time",
            description:
                <>
                    <p>
                        Betal kun for minuttene som brukes, ikke per påbegynt kvarter.
                    </p>
                </>,
        }],
    },
    {
        type: "FIXED",
        name: "Fastpris",
        details: [{
            title: "Etter avtale",
            description:
                <>
                    <p>
                        Få et uforpliktende tilbud som du kan vurdere før arbeidet begynner.
                    </p>
                </>,
        }],
    },
    {
        type: "SUBSCRIPTION",
        name: "Bedriftsabonnement",
        for_business: true,
        details: [
            {
                title: "Basis",
                description:
                    <>
                        <p>
                            10 t/mnd&emsp;|&emsp;14.000,- per måned
                        </p>
                    </>,
            },
            {
                title: "Standard",
                description:
                    <>
                        <p>
                            30 t/mnd&emsp;|&emsp;40.000,- per måned
                        </p>
                    </>,
            },
            {
                title: "Pluss",
                description:
                    <>
                        <p>
                            70 t/mnd&emsp;|&emsp;80.000,- per måned
                        </p>
                    </>,
            },
            {
                title: "Vet ikke",
                description:
                    <>
                        <p>
                            Ikke sikker hvor mye du trenger?
                        </p>
                    </>,
            },
        ],
    },
];

const defaultStageMakeup = [
    (props) => {
        if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
        return <DescribeHelp {...props}/>
    },
    (props) => {
        if (props?.flag === "STATE_DESCRIPTION") return "Specifying all counterparts related to the case.";
        return <Counterparts {...props}/>
    },
    (props) => {
        if (props?.flag === "STATE_DESCRIPTION") return "Declaring all relevant associates/signers.";
        return <Associates {...props}/>
    },
];

const defaultStageMakeupPersonal = [
    (props) => {
        if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
        return <DescribeHelp {...props}/>
    },
    (props) => {
        if (props?.flag === "STATE_DESCRIPTION") return "Specifying all counterparts related to the case.";
        return <Counterparts {...props}/>
    },
    (props) => {
        if (props?.flag === "STATE_DESCRIPTION") return "Declaring that they accept our privacy terms.";
        return <AcceptTerms {...props}/>
    },
];

const categoryData = [
    {
        internal_id: 4,
        name: "Arbeidsliv & HR",
        color: "#d898c6",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 3,
        name: "Avtalerett",
        color: "#f2af89",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 5,
        name: "Markedsføring",
        color: "#7e7dca",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 1,
        name: "Selskapsrett",
        color: "#3d78a4",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 6,
        name: "Personvern & GDPR",
        color: "#58caa1",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 1000,
        name: "Ikke på listen",
        color: "#282828",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring that they accept our privacy terms.";
                    return <AcceptPrivacyPolicy {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 4000,
        name: "Bedriftsabonnement",
        color: "#282828",
        has_contract: false,
        for_subscriptions: true,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Declaring that they accept our privacy terms.";
                    return <AcceptPrivacyPolicy {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 5001,
        name: "Arbeidsliv & HR",
        color: "#d898c6",
        has_contract: false,
        for_business: false,
        stages: [
            defaultStageMakeupPersonal,
        ],
    },
    {
        internal_id: 5002,
        name: "Avtalerett",
        color: "#f2af89",
        has_contract: false,
        for_business: false,
        stages: [
            defaultStageMakeupPersonal,
        ],
    },
    {
        internal_id: 5003,
        name: "Personvern & GDPR",
        color: "#58caa1",
        has_contract: false,
        for_business: false,
        stages: [
            defaultStageMakeupPersonal,
        ],
    },
    {
        internal_id: 5004,
        name: "Kjøp",
        color: "#dc8c8c",
        has_contract: false,
        for_business: false,
        stages: [
            defaultStageMakeupPersonal,
        ],
    },
    {
        internal_id: 5005,
        name: "Bolig og eiendom",
        color: "#f4cc8c",
        has_contract: false,
        for_business: false,
        stages: [
            defaultStageMakeupPersonal,
        ],
    },
    {
        internal_id: 5006,
        name: "Straff",
        color: "#676CB7",
        has_contract: false,
        for_business: false,
        stages: [
            defaultStageMakeupPersonal,
        ],
    },
    {
        internal_id: 5007,
        name: "Utlendingsrett",
        color: "#498480",
        has_contract: false,
        for_business: false,
        stages: [
            defaultStageMakeupPersonal,
        ],
    },
];

const Leno = {
    // init: (context) => {
    //     let payload = {}
    //     setPath(payload, "config.check_aml", true);
    //     context?.dispatch({ type: context?.defaultAction, payload: payload});
    // },

    props: () => {
        return {
            checkAml: true,
            prices: priceData,
            services: categoryData,
        };
    },
}

export default Leno;
