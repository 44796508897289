import React, { useContext, useEffect, useState } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useTextfieldInput from "@/res/hooks/Form/useTextfieldInput";
import { useTranslation } from "react-i18next";
import { pageTitle } from "@/res/helpers/PageHelper";

const DescribeAndSelect = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();
    const [tags, setTags] = useState([]);

    const fieldInput = useTextfieldInput({
        label: (props?.label ? props?.label : t("pages.customise.2-service.describe-help.label")),
        key: "config.help_description", context: serviceContext, rule: {
            minLength: 15, maxLength: 1000,
        },
    });

    useEffect(() => {
        let list = [];

        props?.descriptionTags?.map((item) => {
            if (item?.internal_id === serviceContext?.state?.category?.id) {
                item?.tags?.map((tag) => {
                    let regex = new RegExp(escapeRegExp(tag), "ig");
                    if (fieldInput.value.match(regex)) {
                        list.push(tag);
                    }
                });
            }
        });

        setTags(list);
    }, [fieldInput.value])

    const toggleTag = (tag, exists) => {
        if (exists) {
            let regex = new RegExp(escapeRegExp(tag), "ig");
            let strIndex = fieldInput.value.search(regex);

            if (strIndex >= 0) {
                let excess = [0, 0];
                let length = tag.length;

                for (let i = 0; i < 2; i++) {
                    for (let g = (1 - i); ; g++) {
                        if (i === 0) {
                            if (strIndex === 0 ||
                                strIndex - (g - 1) < 0 ||
                                ![" ", ","].includes(fieldInput.value[strIndex - g])
                            ) {
                                excess[i] = (g - 1);
                                break
                            }
                        } else {
                            if (strIndex + length + g >= (fieldInput?.value?.length ?? 0) ||
                                (![" "].includes(fieldInput.value[strIndex + length + g]))
                            ) {
                                excess[i] = (g);
                                break
                            }
                        }
                    }
                }

                let value = fieldInput.value.replace(fieldInput.value.slice(strIndex - excess[0], strIndex + length + excess[1]), "");
                if (value.match(/[, ]+/g)?.join("")?.length === value?.length) {
                    fieldInput.setValue("");
                } else {
                    fieldInput.setValue(value.trim());
                }
            }
        } else {
            if (fieldInput.value.length > 0) {
                let has_space = false;
                let has_comma = false;

                for (let g = 1;; g++) {
                    if ((fieldInput.value?.length ?? 0) - g < 0 ||
                        ![" ", ","].includes(fieldInput.value[(fieldInput.value?.length ?? 0) - g])
                    ) {
                        break;
                    }

                    if ([" "].includes(fieldInput.value[(fieldInput.value?.length ?? 0) - g])) has_space = true;
                    if ([","].includes(fieldInput.value[(fieldInput.value?.length ?? 0) - g])) has_comma = true;
                }

                fieldInput.setValue(fieldInput.value + (has_comma ? "" : ",") + (has_space ? "" : " ") + tag + ",");
            } else {
                fieldInput.setValue(`${ tag }, `);
            }
        }
    };

    const escapeRegExp = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    return (
        <>
            { pageTitle("How can we help") }

            <div className="container funnel-container funnel-container-bottom-buffer">
                <div className="row justify-content-center">
                    <div className="funnel-default-card">
                        <h5>
                            { props?.title != null ?
                                props?.title
                                :
                                t("pages.customise.2-service.describe-help.header")
                            }

                        </h5>
                        {fieldInput.render()}

                        <label>
                            {t("pages.customise.2-service.describe-and-select.label")}
                        </label>
                        <div className="tag-container">
                            {props?.descriptionTags?.map((item) => {
                                if (item?.internal_id !== serviceContext?.state?.category?.id) {
                                    return null;
                                }

                                return item?.tags?.map((tag, index) => {
                                    let exists = false;
                                    if (tags.find(item => { return item === tag }) != null) {
                                        exists = true;
                                    }

                                    return (
                                        <div className={"tag-item" + (exists ? " selected" : "")} key={"tag" + index} onClick={() => toggleTag(tag, exists)}>
                                            {tag}
                                        </div>
                                    );
                                })
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DescribeAndSelect;
