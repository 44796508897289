import React, { useContext } from "react";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import { ServiceContext } from "@/res/context/ServiceContext";
import useEmailInput from "@/res/hooks/Form/useEmailInput";
import Infobox from "@/res/components/Extra/Infobox";
import { pageTitle } from "@/res/helpers/PageHelper";
import { useTranslation } from "react-i18next";
import usePhoneInput from "@/res/hooks/Form/usePhoneInput";
import useStringInput from "@/res/hooks/Form/useStringInput";

const FormerAccountantAuditor = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    const accountantExistsInput = useToggleInput({
        context: serviceContext, key: "config.previous_accountant.exists",
        label: t("pages.customise.2-service.former-accountant-auditor.toggle-accountant"),
        rule: { hideInput: true },
    });
    const accountantNameInput = useStringInput({
        context: serviceContext, key: "config.previous_accountant.name", class: "small",
        label: t("_shared.form.name-label"), placeholder: t("_shared.form.name-placeholder"),
        rule: { required: false },
    });
    const accountantEmailInput = useEmailInput({
        context: serviceContext, key: "config.previous_accountant.email", class: "small",
        label: t("_shared.form.email-label"), placeholder: t("_shared.form.email-placeholder"),
        rule: { required: false },
    });
    const accountantPhoneInput = usePhoneInput({
        context: serviceContext, key: "config.previous_accountant.phone_number", class: "small",
        label: t("_shared.form.phone-label"),
        rule: { required: false },
    });

    const auditorExistsInput = useToggleInput({
        context: serviceContext, key: "config.previous_auditor.exists",
        label: t("pages.customise.2-service.former-accountant-auditor.toggle-auditor"),
        rule: { hideInput: true },
    });
    const auditorNameInput = useStringInput({
        context: serviceContext, key: "config.previous_auditor.name", class: "small",
        label: t("_shared.form.name-label"), placeholder: t("_shared.form.name-placeholder"),
        rule: { required: false },
    });
    const auditorEmailInput = useEmailInput({
        context: serviceContext, key: "config.previous_auditor.email", class: "small",
        label: t("_shared.form.email-label"), placeholder: t("_shared.form.email-placeholder"),
        rule: { required: false },
    });
    const auditorPhoneInput = usePhoneInput({
        context: serviceContext, key: "config.previous_auditor.phone_number", class: "small",
        label: t("_shared.form.phone-label"),
        rule: { required: false },
    });

    return (
        <>
            { pageTitle("Current or former accountant/auditor") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container" style={{ zIndex: "1" }}>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.former-accountant-auditor.header")}
                                </h5>

                                <div className="input-attachment">
                                    <small>
                                        {t("pages.customise.2-service.former-accountant-auditor.disclaimer")}
                                    </small>
                                </div>

                                <div className={ "inner-container-header" + (accountantExistsInput.value ? " show" : "")}>
                                    {accountantExistsInput.render()}
                                </div>
                                <div className={ "inner-container inner-container-attached" + (accountantExistsInput.value ? " show" : "")}>
                                    { accountantNameInput.render() }
                                    <div className="input-group always">
                                        { accountantEmailInput.render() }
                                        { accountantPhoneInput.render() }
                                    </div>
                                </div>

                                <div className={ "inner-container-header" + (auditorExistsInput.value ? " show" : "")}>
                                    {auditorExistsInput.render()}
                                </div>
                                <div className={ "inner-container inner-container-attached" + (auditorExistsInput.value ? " show" : "")}>
                                    { auditorNameInput.render() }
                                    <div className="input-group always">
                                        { auditorEmailInput.render() }
                                        { auditorPhoneInput.render() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Infobox title={t("pages.customise.2-service.former-accountant-auditor.why-we-need-this.header")}>
                    <p>
                        {t("pages.customise.2-service.former-accountant-auditor.why-we-need-this.body")}
                    </p>
                </Infobox>
            </div>
        </>
    );
};

export default FormerAccountantAuditor;
