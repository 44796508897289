import React from "react";
import DescribeHelp from "@/res/components/ServicePages/DescribeHelp";
import Basics from "@/res/components/ServicePages/Basics";
import Associates from "@/res/components/ServicePages/Associates";
import FormerAccountantAuditor from "@/res/components/ServicePages/FormerAccountantAuditor";

const priceData = (props) => [
    {
        type: "PAYG",
        name: "Minute price",
        details: [{
            title: "$85,- per hour",
            description:
                <>
                    <p>
                        Pay only for the minutes used, not per quarter started.
                    </p>
                </>,
        }],
    },
    {
        type: "FIXED",
        name: "Fixed price",
        details: [{
            title: "By appointment",
            description:
                <>
                    <p>
                        Get a non-binding offer that can be considered before we start the work.
                    </p>
                </>,
        }],
    },
];

const defaultStageMakeup = [
    (props) => {
        return <Basics {...props}/>
    },
    // (props) => {
    //     return <FormerAccountantAuditor {...props}/>
    // },
    // (props) => {
    //     return <Associates {...props}/>
    // },
];

const categoryData = [
    {
        internal_id: 1,
        name: "Advanced Services",
        color: "#77a6c3",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 2,
        name: "Monthly Financial Reporting",
        color: "#f2af89",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 3,
        name: "Purchase Hours",
        color: "#58caa1",
        stages: [
            defaultStageMakeup,
        ],
    },
    {
        internal_id: 4,
        name: "Basic Bookkeeping",
        color: "#d898c6",
        stages: [
            defaultStageMakeup,
        ],
    },
];

const softwareData = (t) => [
    {
        label: t("pages.customise.2-service.basics.accounting-software-options.default-header"),
    },
    {
        internal_id: 1,
        label: "QuickBooks Online",
    },
    {
        internal_id: 2,
        label: "Xero",
    },
    {
        internal_id: 999,
        label: t("pages.customise.2-service.basics.accounting-software-options.other"),
    },
]

const Acus = {
    props: () => {
        return {
            prices: priceData,
            services: categoryData,
            softwares: softwareData,
            // descriptionTags: descriptionTags,
        };
    },
}

export default Acus;
