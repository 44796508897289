import React, { useState } from "react";
import InfoIcon from "@/res/assets/icons/info-icon.svg";

const Infobox = (props) => {
    const [expand, setExpand] = useState(false);

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="funnel-default-card" style={{ borderColor: "#4D8ED9" }}>
                        <div
                            className={ "toggle-container toggle-header " + (expand ? "expand" : "") }
                            style={{ color: "#5c97dc" }}
                            onClick={() => setExpand(!expand)}
                        >
                            <label>
                                { props?.title }
                            </label>
                        </div>
                        <div className="card-icon" style={{ backgroundImage: `url(${InfoIcon})` }}/>
                        <div className="funnel-info-box">
                            {expand && props?.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Infobox;
