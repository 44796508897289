import React, { useCallback, useEffect, useRef, useState } from "react";
import useRouter from "@/res/components/router";
import { homeUrl, IS_SERVER } from "@/res/helpers/ServiceHelper";
import useEventListener from "@/res/hooks/EventListener";

import SynegaLogo from "@/res/assets/images/logo-default.svg";
import queryString from "query-string";
import useApi from "@/res/hooks/SynegaApi";
import { useGenericContext } from "@/res/context/generic";
import useIsomorphicLayoutEffect from "@/res/hooks/UseIsomorphicLayoutEffect";

const SCROLL_DISTANCE = 10;

const Nav = (props) => {
    const api = useApi(2);
    const router = useRouter();
    const { isLoggedIn, logOff } = useGenericContext();

    const navRef = useRef();
    const [opacity, setOpacity] = useState(0);
    const [paths, setPaths] = useState([]);

    const goHome = useCallback((prefetch = false) => {
        if (props?.isCrmEnabled && !prefetch) {
            window.location.href = "https://www.synega.no/crm";
            return;
        }

        if ((paths?.length ?? 0) === 0) {
            return;
        }

        let gateway = paths[0];
        if (gateway === "forms") {
            formsGateway(prefetch);
            return;
        }

        if (paths?.length > 2) {
            if (paths[1] === "documents" && !prefetch) {
                window.location.href = homeUrl(gateway);
                return;
            }

            pushOrFetch(`/${ paths[0] }/${ paths[1] }`, prefetch);
        } else if (paths?.length > 1) {
            if (gateway === "global") {
                pushOrFetch("/global", prefetch);
            } else if (!prefetch) {
                window.location.href = homeUrl(gateway);
            }
        } else {
            if (gateway === "global" && !prefetch) {
                window.location.href = "https://www.synega.no/global";
            } else {
                pushOrFetch("/", prefetch);
            }
        }
    }, [paths]);

    const formsGateway = async (prefetch = false) => {
        let query = queryString.parse(window.location.search);
        let country = query?.c;

        // Attempting to fetch the country based on IP.
        if ((country?.length ?? 0) === 0) {
            await api.get("/data/country").then(res => {
                if (res?.status === "success") {
                    country = res?.data?.countryAlpha2;
                }
            });
        }

        // Attempting to fetch the country based on user browser language.
        // This is a very unreliable approach as it is quite likely the user has set their
        // browser to a language that does not indicate their country.
        if ((country?.length ?? 0) === 0) {
            country = navigator.language;
            if (country?.length > 2) {
                country = country.split("-")[1].toLowerCase();
            }
        }

        if (!prefetch) {
            window.location.href = homeUrl(country);
        }
    };

    const pushOrFetch = (url, prefetch) => {
        if (prefetch) {
            router.prefetch(url);
        } else {
            router.push(url);
        }
    }

    useEffect(() => {
        let temp_paths = window.location.pathname.split("/");
        temp_paths = temp_paths.filter(item => {
            return item !== "";
        });
        let eq = Array.isArray(temp_paths) &&
            Array.isArray(paths) &&
            temp_paths.length === paths.length &&
            temp_paths.every((val, index) => val === paths[index]);
        if (!eq) {
            setPaths(temp_paths);
        }
    });

    useEffect(() => {
        goHome(true);
    }, [paths]);

    const handler = useCallback(() => {
        const opacity = window.scrollY > SCROLL_DISTANCE ? 1 :
            ((window.scrollY) / SCROLL_DISTANCE);
        setOpacity(opacity);
    }, []);

    useEventListener("scroll", handler);
    useEffect(() => {
        const opacity = window.scrollY > SCROLL_DISTANCE ? 1 :
            ((window.scrollY) / SCROLL_DISTANCE);
        setOpacity(opacity);
    }, []);

    useIsomorphicLayoutEffect(() => {
        if (IS_SERVER) return;
        navRef.current.style.backgroundColor = `rgba(255,255,255, ${ opacity ?? "0" })`;
    }, [opacity]);

    return (
        <nav ref={navRef} style={props.style}>
            <div className="nav__row">
                <div className="nav__container--left">
                    <div className="nav__logo" onClick={() => goHome()}>
                        <img
                            className={props?.className}
                            loading="lazy"
                            src={SynegaLogo}
                            alt=""
                        />
                    </div>
                    <span
                        style={{
                            color: "#244a66",
                            fontWeight: "800",
                            fontSize: "1.5rem",
                            lineHeight: "40px",
                            marginLeft: "1rem",
                        }}
                        id="nav-demo-label"
                        className="hide-mobile-up"
                    >
                        DEMO
                    </span>
                </div>
                <div className="nav__container--right">
                    <div className="nav__user--container">
                        <div className={ "nav__user" + (isLoggedIn ? " nav__user--logged-on" : "") }>
                            <div className="nav__user--name">
                                TT
                            </div>
                            <div className="nav__action">
                                <div className="nav__action--logout" onClick={logOff}>
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
