import React, { useEffect } from "react";
import useErrorMessages from "@/components/message/error";
import useForm from "./form";
import useProps from "./props";
import Label from "@/hooks/form/label";
import { resolvePath } from "@/helpers/path";
import { innerText } from "@/helpers/utility";

export const SelectionInput = ({ label, ...params }) => {
    const { defaultProps, data, props } = useProps(params);
    const options = data?.options ?? [];

    return (
        <div { ...props }>
            <Label {...props} {...defaultProps} label={label}/>
            <select { ...defaultProps }>
                { options.map?.((item, index) => {
                    const label = (typeof item?.label === "object") ? item?.label?.props?.children : item?.label;

                    return (
                        <option key={"option" + index} value={index}>
                            {label} {item?.postfix}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

const useSelectionInput = (params) => {
    const { message, KEYS } = useErrorMessages();

    const onChange = (e) => {
        setValue({ index: e.target.value });
    }

    const onValidation = (element, value) => {
        if ((rules?.required ?? true) && !(value?._ii >= 1)) {
            element?.setCustomValidity?.(message(KEYS.REQUIRED));
            return false;
        }
    }

    const {
        key,
        value,
        props,
        rules,
        isValid,
        getElement,
        forceChange,
    } = useForm({
        id: "selectionInput", type: "select",
        ...params,
        onChange,
        onValidation,
    });

    useEffect(() => {
        isValid();
    }, []);

    useEffect(() => {
        if (!params?.context) return;
        if (!isNaN(Number(resolvePath(params?.context?.state, key)?._ii))) return;

        let element = getElement();
        if (!element) return;

        element.value = 0;
        isValid();
    }, [resolvePath(params?.context?.state, key)])

    const forceValue = (value) => {
        forceChange(key, value);
    };

    const reset = () => {
        forceValue(params?.default ?? null);
    }

    // _ii ("internal index")
    const setValue = ({ index: _ii, label }) => {
        let _index = _ii >= 0 ? _ii : props?.data?.options.findIndex(item => item.label === label);
        let option = props?.data?.options[_index];

        let payload = {
            ...option,
            _ii: _index,
            label: innerText(option?.label),
            id: option?.internal_id ?? option?.id,
        }

        Object.keys(value ?? {}).forEach(key => {
            payload[key] ||= null;
        })

        forceValue(payload);
        isValid({ _ii: _index });
    }

    return {
        value,
        props,
        reset, isValid, setValue,
    };
};

export default useSelectionInput;
