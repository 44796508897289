import React, { useState } from "react";
import SynegaLogo from "@/res/assets/images/logo-default.svg";
import HomeSVG from "@/assets/icons-js/home";
import SigningDocumentSVG from "@/assets/icons-js/signing-document";
import DocumentsSVG from "@/assets/icons-js/documents";
import PenToolSVG from "@/assets/icons-js/pen-tool";
import NavDropdown from "@/components/tms/sidebar-dropdown";
import NavLink from "@/components/tms/sidebar-link";
import ExternalLinkSVG from "@/assets/icons-js/external-link";
import queryString from "query-string";
import BusinessNetworkSVG from "@/assets/icons-js/business-network";
import { IS_SERVER } from "@/helpers/utility";
import NonSsrWrapper from "@/components/wrappers/non-ssr-wrapper";
import ResumeWebsiteSVG from "@/assets/icons-js/resume-website";
import TransactionSVG from "@/assets/icons-js/transaction";

const Sidebar = (props) => {
    const [hidden] = useState(IS_SERVER ? "" : queryString.parse(window.location.search)?.hidden)

    return (
        <nav className="nav__sidebar">
            <div className="nav__logo">
                <img
                    className={props?.className}
                    loading="lazy"
                    src={SynegaLogo}
                    alt=""
                />
            </div>

            <hr/>

            <div className="nav__links py-1">
                <NavLink logoComponent={<HomeSVG/>} href="/tms">
                    Dashboard
                </NavLink>
                <NavDropdown logoComponent={<SigningDocumentSVG/>} label={"Avtaler"} skey="#_contracts" default="open">
                    <NavLink href="/tms/new">
                        Ny
                    </NavLink>
                    <NavLink href="/tms/update">
                        Oppdater
                    </NavLink>
                    <NavLink href="/tms/other">
                        Andre avtaler
                    </NavLink>
                </NavDropdown>
                {/*<NonSsrWrapper>*/}
                {/*    { (hidden || true) &&*/}
                {/*        <NavLink logoComponent={<DocumentsSVG/>} href="/tms/envelopes">*/}
                {/*            Dokumenter*/}
                {/*        </NavLink>*/}
                {/*    }*/}
                {/*</NonSsrWrapper>*/}
                <NavDropdown logoComponent={<DocumentsSVG/>} label={"Dokumenter"} skey="#_envelopes" default="open">
                    <NavLink href="/tms/documents">
                        Send
                    </NavLink>
                    <NavLink href="/tms/envelopes">
                        Status
                    </NavLink>
                    <NavLink href="/tms/envelopes/history">
                        Historikk
                    </NavLink>
                </NavDropdown>
                <NavDropdown logoComponent={<ResumeWebsiteSVG/>} label={"Kundesjekk"} skey="#_kyc" default="open">
                    <NavLink href="/tms/kyc/upload">
                        Send
                    </NavLink>
                    <NavLink href="/tms/kyc/status">
                        Status
                    </NavLink>
                </NavDropdown>
                <NavLink logoComponent={<TransactionSVG/>} href="/tms/credit">
                    Kreditt
                </NavLink>
                <NavLink logoComponent={<PenToolSVG/>} href="/tms/templates" data-implemented="false">
                    Maler
                </NavLink>
                {/*<NavLink logoComponent={<PenToolSVG/>} href="/tms/example" data-implemented="false">*/}
                {/*    Example*/}
                {/*</NavLink>*/}
            </div>
        </nav>
    );
};

export default Sidebar;
