import { useEffect, useState } from "react";
import { setPath } from "../../helpers/ObjectHelpers";

const useForm = (props) => {
    const [values, setValues] = useState({});
    const [caret, setPosition] = useState(0);
    const [target, setTarget] = useState(null);

    useEffect(() => {
        if (caret != null && target != null) {
            try {
                target.setSelectionRange(caret, caret);
            } catch {
                /* Ignore catch */
                /* This catch exists mostly because Safari/Apple and their date
                 * pickers seem to cause a type error. This might also be a fix
                 * for the file input on Apple devices. */
            }
        }
    }, [target?.value]);

    const forceValue = (key, value, overwrite = false) => {
        let payload = { ...values }
        setPath(payload, key, value);

        setValues(payload);
        if (props?.key?.length > 0 && props?.context != null) {
            if (overwrite) {
                try {
                    props?.context?.dispatch({ type: props?.context?.actions?.STRICT_SAVE, payload: payload });
                } catch (e) {
                    Sentry.captureMessage(e)
                    props?.context?.dispatch({ type: props?.context?.defaultAction, payload: payload });
                }
            } else {
                props?.context?.dispatch({ type: props?.context?.defaultAction, payload: payload });
            }
        }
    };

    const resetValue = key => {
        let payload = { ...values };
        setPath(payload, key, null);

        setValues(payload);
        if (props?.key?.length > 0 && props?.context != null) {
            props?.context?.dispatch({ type: props?.context?.defaultAction, payload: payload });
        }
    };

    const handleChange = (e, pattern = null) => {
        try {
            setPosition(e.target.selectionStart);
        } catch {
            /* Ignore catch */
            /* This catch exists mostly because Safari/Apple and their date
             * pickers seem to cause a type error. This might also be a fix
             * for the file input on Apple devices. */
        }
        setTarget(e.target);

        let payload = { ...values };
        setPath(payload, e.target.name, e.target.value);

        setValues(payload);
        if (props?.key?.length > 0 && props?.context != null) {
            props?.context?.dispatch({ type: props?.context?.defaultAction, payload: payload });
        }
    };

    return {
        values, forceValue, resetValue, handleChange,
    };
};

export default useForm;
