export const resolvePath = (object, path, defaultValue = "") => path
    // eslint-disable-next-line
    .split(/[\.\[\]\'\"]/)
    .filter(p => p)
    .reduce((o, p) => o ? o[p] : defaultValue, object);

export const resolvePathWithNull = (object, path, defaultValue = null) => path
    // eslint-disable-next-line
    .split(/[\.\[\]\'\"]/)
    .filter(p => p)
    .reduce((o, p) => o ? o[p] : defaultValue, object);

export const setPath = (object, path, value) => path
    .split(".")
    .reduce((o, p, i) => o[p] = path.split(".").length === ++i ? value : o[p] || {}, object);

export const formatNumber = (number) => {
    return number
        ?.toString()
        ?.split("")
        ?.reverse()
        ?.join("")
        ?.match(/.{1,3}/g)
        ?.join(" ")
        ?.split("")
        ?.reverse()
        ?.join("");
};

export const uuid = () => "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
});

export const calculatePricing = (ppm, additional = 0) => {
    let number = (ppm * 12) + additional;
    return formatNumber(number);
};
