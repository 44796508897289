import React from "react";

const useErrorMessages = (props) => {
    const KEYS = {
        REQUIRED: "REQUIRED",
    }

    const message = (key) => {
        const _key = (key ?? "").toUpperCase();

        switch (_key) {
            case KEYS.REQUIRED:
                return "Dette feltet er obligatorisk";

            default:
                try {
                    Sentry.captureMessage(`Error message key does not exist (${key})`);
                } catch (e) { console.error(e) }

                return "Invalid";
        }
    }

    return { message, KEYS }
};

export default useErrorMessages;
