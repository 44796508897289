import React, { useContext } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useNumberInput from "@/res/hooks/Form/useNumberInput";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const Payroll = (props) => {
    const serviceContext = useContext(ServiceContext);
    const { state } = serviceContext;

    const existsInput = useToggleInput({
        context: serviceContext, key: "config.payroll.exists",
        label: "Skal selskapet betale ut lønn?",
        rule: { hideInput: true },
    });

    const numberInput = useNumberInput({
        label: "Hvilken dag i måneden skal det utbetales lønn?",
        rule: { required: true, minValue: 1, maxValue: 28 },
        context: serviceContext, key: "config.payroll.date",
    });

    const annualReportingToggle = useToggleInput({
        context: serviceContext, key: "config.annual_reporting",
        label: "Skal årsoppgjøret utføres av Synega?", default: true,
        rule: { hideInput: true },
    });

    return (
        <>
            { pageTitle("Payroll details") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                {existsInput.render()}
                                {existsInput.value &&
                                <>
                                    <br/>
                                    {numberInput.render()}
                                    <div className="input-attachment">
                                        <small>
                                            Velg mellom den 1. og 28. i måneden
                                        </small>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                { state?.client?.is_sales &&
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="funnel-default-card card--sales">
                                    {annualReportingToggle.render()}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Payroll;
