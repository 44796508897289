import React, { useLayoutEffect } from "react";
import SynegaLogo from "@/res/assets/images/logo-default.svg";
import { IS_PRODUCTION, IS_SERVER } from "@/res/helpers/ServiceHelper";
import Link from "next/link";

const ClientNav = ({ hideNav, ...props }) => {
    useLayoutEffect(() => {
        if (IS_SERVER) return;
        if (IS_PRODUCTION) return;

        let element = document.getElementById("__nav");
        if (hideNav) {
            element.classList.add("hide");
        }
    }, [])

    return (
        <nav id="__nav" className={ hideNav ? "hide" : "" } suppressHydrationWarning>
            <Link href="https://www.synega.com/no-no" passHref>
                <div className="nav__logo">
                    <img
                        className={props?.className}
                        loading="lazy"
                        src={SynegaLogo}
                        alt=""
                    />
                </div>
            </Link>
        </nav>
    );
};

export default ClientNav;
