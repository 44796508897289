import React from "react";
import Head from "next/head";

const DefaultHead = (props) => {
    return (
        <Head>
            <title>Synega</title>
            <meta property="og:title" content="Synega"/>
            <meta name="twitter:title" content="Synega"/>

            <meta property="og:site_name" content="Synega"/>
            <meta property="og:type" content="website"/>

            <meta name="twitter:image" content="/synega-blue.png"/>
            <meta property="og:image" content="/synega-blue.png"/>

            <meta property="og:url" content="https://sales.synega.com"/>
            <meta name="author" content="Synega AS"/>

            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik:300,regular,500,600,700,800,900,300italic,italic,500italic,600italic,700italic,800italic,900italic|Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic|Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic&#038;subset=latin,latin-ext&#038;display=swap" type="text/css" media="all"/>
        </Head>
    );
};

export default DefaultHead;
