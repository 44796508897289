import React, { useContext, useEffect, useState } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useHiddenInput from "@/res/hooks/Form/useHiddenInput";
import Infobox from "@/res/components/Extra/Infobox";
import Associate from "@/res/components/Extra/Associate";
import useEmailInput from "@/res/hooks/Form/useEmailInput";
import usePhoneInput from "@/res/hooks/Form/usePhoneInput";
import { pageTitle } from "@/res/helpers/PageHelper";
import { Trans, useTranslation } from "react-i18next";

const Associates = (props) => {
    const [t] = useTranslation();
    const serviceContext = useContext(ServiceContext);
    const [index, setIndex] = useState(0);

    const hiddenInput = useHiddenInput({
        context: serviceContext, key: "config.shareholders.count",
    });
    const emailInput = useEmailInput();
    const phoneInput = usePhoneInput();

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [])

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [hiddenInput.value, serviceContext?.state?.config])

    // Can only proceed from evaluation returns true.
    const evaluate = () => {
        let isInvalid = false;
        let errors = [];

        const is_sales = serviceContext?.state?.client?.is_sales;
        const shareholders = serviceContext?.state?.config?.shareholders;
        let totalShares = 0;

        for (let i = 0; i < (hiddenInput.value); i++) {
            const shareholder = shareholders?.[i];

            if (shareholder == null) {
                isInvalid = true;
                errors.push({ index: i, value: "missing_entry" });
                continue;
            }

            totalShares += Number(shareholder?.share ?? 0);
            if (!emailInput.isValid(shareholder?.email ?? "")) {
                isInvalid = true;
                errors.push({ index: i, value: "email" });
            }
            if (!phoneInput.isValid(shareholder?.phone?.["e.164"] ?? "")) {
                isInvalid = true;
                errors.push({ index: i, value: "phone" });
            }
            if ((isNaN(shareholder?.share) && !is_sales) ||
                ((Number(shareholder?.share ?? 0)) === 0 && !is_sales) ||
                (Number(shareholder?.share) > 100)) {
                isInvalid = true;
                errors.push({ index: i, value: "shares" });
            }
            if ((shareholder?.name ?? "").length < 3) {
                isInvalid = true;
                errors.push({ index: i, value: "name" });
            }
        }

        if ((totalShares === 0 && !is_sales) || totalShares > 100) {
            isInvalid = true;
            errors.push({ index: null, value: "total_shares" });
        }

        // if (isInvalid) console.log(errors);
        return !isInvalid;
    };

    useEffect(() => {
        if (hiddenInput.value == null || hiddenInput.value === 0 || (typeof hiddenInput.value) !== "number") {
            hiddenInput.setValue(1);
        }

        if (index >= (hiddenInput?.value ?? 1)) {
            let val = (hiddenInput?.value ?? 1) - 1;
            setIndex(val >= 0 ? val : 0);
        }
    }, [hiddenInput.value])

    return (
        <>
            { pageTitle("Associates") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.associates.header")}
                                </h5>
                                <p>
                                    {t("pages.customise.2-service.associates.prompt")}
                                </p>
                                <div className="button-group button-group-space-around">
                                    { [...Array(4)].map((e, i) => {
                                        let index = i + 1;

                                        return (
                                            <div
                                                className={ "button squared " + (hiddenInput.value !== index ? "secondary" : "") }
                                                onClick={() => hiddenInput.setValue(index)}
                                                key={i}
                                            >
                                                {index}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Infobox title={t("pages.customise.2-service.associates.info-who-has-to-sign.header")}>
                    <ol>
                        <Trans i18nKey={"pages.customise.2-service.associates.info-who-has-to-sign.body"}>
                            <li/>
                            <li/>
                            <li/>
                            <li/>
                            <li/>
                        </Trans>
                    </ol>
                </Infobox>

                { hiddenInput.value > 0 &&
                <div className="container" style={{ marginTop: "5rem" }}>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <div className="type-container type-container-toggleable">
                                    <div className="type-buttons">
                                        { [...Array(hiddenInput?.value)].map((e, i) => {
                                            return (
                                                <span className="type-button-container" key={i}>
                                                    <div
                                                        onClick={() => setIndex(i)}
                                                        className={ "type-button " + (index === i ? "active" : "")}
                                                    >
                                                        {i + 1}
                                                    </div>
                                                    { i + 1 < hiddenInput?.value &&
                                                    <div className="type-divider"/>
                                                    }
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                                <Associate key={`associate${index}`} index={index}/>
                            </div>
                        </div>
                    </div>
                </div>
                }

                <Infobox title={t("pages.customise.2-service.associates.info-why-we-need-this.header")}>
                    <Trans i18nKey={"pages.customise.2-service.associates.info-why-we-need-this.body"}>
                        <h5>
                        </h5>
                        <p>
                        </p>
                        <p>
                            <strong/>
                        </p>
                    </Trans>
                </Infobox>
            </div>
        </>
    );
};

export default Associates;
