import React from "react";
import DefaultHead from "@/components/defaults/head";
import Topbar from "@/components/tms/topbar";
import Sidebar from "@/components/tms/sidebar";
import { MainWrapper } from "@/res/context/main";

const TmsComponentWrapper = ({ Component, pageProps }) => {
    return (
        <div id="__tms">
            {/*<I18nProvider i18n={i18n}>*/}
            <DefaultHead/>
            <MainWrapper storageKey="_synega-main" storage="local">
                <Sidebar/>
                <main style={{ marginTop: "64px" }}>
                    <Topbar/>
                    <Component {...pageProps} />
                </main>
            </MainWrapper>
            {/*</I18nProvider>*/}
        </div>
    );
};

export default TmsComponentWrapper;
