import React, { useContext } from "react";
import useTextfieldInput from "@/res/hooks/Form/useTextfieldInput";
import useSelectionInput from "@/res/hooks/Form/useSelectionInput";
import { pageTitle } from "@/res/helpers/PageHelper";
import { useTranslation } from "react-i18next";
import { ServiceContext } from "@/res/context/ServiceContext";
import useNumberInput from "@/res/hooks/Form/useNumberInput";

const Basics = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    const fieldInput = useTextfieldInput({
        label: props?.fieldLabel ?? t("pages.customise.2-service.basics.describe-needs-label"),
        key: "config.help_description", context: serviceContext, rule: {
            maxLength: 500, required: false,
        },
    });

    const yearInput = useNumberInput({
        label: props?.dateLabel ?? t("pages.customise.2-service.basics.auno-starting-date-label"),
        context: serviceContext, key: "config.start_date", rule: {
            required: true, default: (new Date()).getFullYear(), minValue: (new Date()).getFullYear() - 1, maxValue: (new Date()).getFullYear() + 3,
        },
    });

    const selectionInput = useSelectionInput({
        label: props?.optionsLabel ?? t("pages.customise.2-service.basics.accounting-software-label"), options: props?.softwares(t),
        rule: { required: true },
        context: serviceContext, key: "config.software",
    })

    return (
        <>
            { pageTitle("The basics") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.basics.header-miscellaneous")}
                                </h5>
                                {yearInput.render()}
                                <br/>
                                {selectionInput.render()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.basics.header-description")}
                                </h5>
                                {fieldInput.render()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Basics;
