import React from "react";

const Spinner = ({ className, style }) => {
    return (
        <div className={ "spinner " + (className ?? "") } style={style}>
            <div className="circle-1"/>
            <div className="circle-2"/>
            <div className="circle-3"/>
            <div className="circle-4"/>
            <div className="circle-5"/>
            <div className="circle-6"/>
            <div className="circle-7"/>
            <div className="circle-8"/>
            <div className="circle-9"/>
            <div className="circle-10"/>
            <div className="circle-11"/>
            <div className="circle-12"/>
        </div>
    );
};

export default Spinner;
