import { useEffect, useState } from "react";
import { resolvePath, resolvePathWithNull, setPath } from "@/helpers/path";
import useUpdateEffect from "@/res/hooks/UpdateEffect";
import { innerText, IS_SERVER } from "@/helpers/utility";

const useForm = (props) => {
    const [values, setValues] = useState({});
    const [caret, setPosition] = useState(0);
    const [target, setTarget] = useState(null);
    const [isBlurred, setBlur] = useState(false);

    const key = props?.key ?? "default";
    const settings = props?.settings;
    const context = props?.context;
    const elementId = `${props.id}-${key}`;
    const value =
        context != null ?
            resolvePath(context?.state, key)
            : settings != null ?
                resolvePath(settings.data, key)?.value
                : values[key];
    const rules = props?.rule ?? props?.rules ?? {};

    useEffect(() => {
        // Attempting to apply default value.
        if (props?.default === undefined) return;

        let _value = context != null ?
            resolvePathWithNull(context?.state, key)
            : settings != null ?
                resolvePath(settings.data, key)?.value
                : values[key];
        if (_value !== null && _value !== undefined) return;

        setDefault();
    }, [])

    useUpdateEffect(() => {
        if (!context || !key) return;
        if (props?.default === undefined) return;

        let _value = resolvePathWithNull(context.state, key);
        if (_value !== null && _value !== undefined) return;

        setDefault();
    }, [resolvePathWithNull(context?.state, key)]);

    const setDefault = () => {
        let _default = props?.default;
        switch (props?.type) {
            case "select":
                if (!isNaN(props?.default)) _default = {
                    ...props?.options[props?.default],
                    label: innerText(props?.options[props?.default]?.label),
                    _ii: props?.default, // "internal index"
                };
                break;
        }

        if (props?.onDefault) {
            props.onDefault();
        } else {
            forceChange(key, _default);
        }
    }

    useEffect(() => {
        if (caret != null && target != null) {
            try {
                target.setSelectionRange(caret, caret);
            } catch {
                /* Ignore catch */
                /* This catch exists mostly because Safari/Apple and their date
                 * pickers seem to cause a type error. This might also be a fix
                 * for the file input on Apple devices. */
            }
        }
    }, [target?.value]);

    const forceChange = (key, value) => {
        let payload = { ...values }
        setPath(payload, key, value);

        setValues(payload);
        if (props?.key?.length > 0 && props?.context != null) {
            props?.context?.dispatch({ type: props?.context?.defaultAction, payload: payload });
        }
    };

    const resetValue = key => {
        let payload = { ...values };
        setPath(payload, key, null);

        setValues(payload);
        if (props?.key?.length > 0 && props?.context != null) {
            props?.context?.dispatch({ type: props?.context?.defaultAction, payload: payload });
        }
    };

    const onChange = (e, pattern = null) => {
        if (props?.onHandleChange) {
            props.onHandleChange(e);
        }

        try {
            setPosition(e.target.selectionStart);
        } catch {
            /* Ignore catch */
            /* This catch exists mostly because Safari/Apple and their date
             * pickers seem to cause a type error. This might also be a fix
             * for the file input on Apple devices. */
        }
        setTarget(e.target);

        let payload = { ...values };
        let _value = e.target.value;
        let _key = key !== "default" ? key : e.target.name ?? key;
        switch (props?.type) {
            case "checkbox":
                if ([true, "true", "on"].includes(_value)) _value = true;
                if ([false, "false", "off"].includes(_value)) _value = false;
                break;
        }

        setPath(payload, _key, _value);

        setValues(payload);
        if (props?.key?.length > 0 && props?.context != null) {
            props?.context?.dispatch({ type: props?.context?.defaultAction, payload: payload });
        }

        if (props?.key?.length > 0 && props?.settings != null) {
            let payload = {};
            setPath(payload, props.key, {
                value: e.target.value,
                is_modified: true,
            })

            props.settings.modify(payload);
        }

        validate(e?.target?.value)
    };

    const validate = (v = null) => {
        if (IS_SERVER) return;

        let element = getElement();
        let _value = v != null ? v : value;

        if (props?.onValidation) {
            let response = props?.onValidation?.(element, _value);
            if (response === true) element?.setCustomValidity?.("");
            if (response === true || response === false) return response;
        }

        element?.setCustomValidity?.("");
        return true;
    }

    const getElement = () => {
        return document.getElementById(elementId);
    }

    const onBlur = (e) => {
        if (!isBlurred) setBlur(true);
        if (props?.onBlur) props?.onBlur(e);
    }

    return {
        key,
        rules,
        value,
        values,
        context,
        getElement,
        resetValue,
        forceChange,
        isValid: validate,
        props: {
            default: {
                id: elementId,
                name: props?.name ?? key,
                value: (() => {
                    switch (props?.type) {
                        case "select":
                            return value?._ii ?? props?.default ?? "";
                        case "checkbox":
                            if ([true, "true", "on"].includes(value)) return true;
                            if ([false, "false", "off"].includes(value)) return false;
                            return null;
                    }

                    return value ?? ""
                })(),
                defaultChecked: (() => {
                    switch (props?.type) {
                        case "checkbox":
                            if (typeof value === "string") {
                                if (value === "true" || value === "on") return value;
                                return false;
                            }
                            return value;
                    }

                    return null;
                })(),
                placeholder: props?.placeholder ?? "",
                className: props?.class ?? "",
                required: props?.required ?? rules?.required ?? true,
                type: props?.type,
                title: props?.title,
                pattern: props?.pattern,
                min: props?.minlength ?? props?.minLength,
                max: props?.maxlength ?? props?.maxLength,
                disabled: props?.disabled,
                autoComplete: props?.autoComplete,

                onChange: props?.onChange ?? onChange,
                onKeyUp: props?.onKeyUp,
                onFocus: props?.onFocus,
                onBlur: onBlur,

                "data-blurred": isBlurred,
            },

            data: {
                options: props?.options ?? [],
            },

            label: props?.label,
            subLabel: props?.subLabel,
            tooltip: props?.tooltip,

            as: props?.as,
        },
    };
};

export default useForm;
