import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ServiceContext } from "@/res/context/ServiceContext";
import Spinner from "@/res/components/LoadingIndicators/Spinner";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const UploadForm = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    const acceptInput = useToggleInput({
        context: serviceContext, key: "config.accept_privacy_policy", class: "tiny",
        label: <Trans i18nKey="pages.customise.3-help.gdpr-compliance.label">
            <a href={t("pages.customise.2-service.accept-privacy-policy.url")} target="_blank" rel="noreferrer"/>
        </Trans>,
    });

    if (serviceContext?.state?.client?.is_sales) {
        return (
            <>
                { pageTitle("Upload form") }

                {!serviceContext?.state?.uploading && !serviceContext?.state?.finished ?
                    <div className="funnel-container funnel-container-bottom-buffer">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="funnel-default-card">
                                        <p style={{ marginTop: "0" }}>
                                            {t("pages.customise.3-help.description-sales")}
                                        </p>
                                        <div style={{ margin: "1.9rem 0" }}/> {/* TODO */ }
                                        <div className="button-group">
                                            <button className="button stretch" type="submit">
                                                {t("pages.customise.3-help.button-upload-sales")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        { props?.finalPage != null &&
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="funnel-default-card">
                                        <p style={{ margin: "0" }}>
                                            <strong>
                                                {t("pages.customise.3-help.disclaimer-sales")}
                                            </strong>
                                        </p>
                                        <div style={{ margin: "1.9rem 0" }}/> {/* TODO */ }

                                        {props?.finalPage?.()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    :
                    <>
                        <Spinner className="absolute"/>
                    </>
                }
            </>
        );
    }

    return (
        <>
            { pageTitle("Upload form") }

            {!serviceContext?.state?.uploading && !serviceContext?.state?.finished ?
                <div className="funnel-container funnel-container-bottom-buffer">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="funnel-default-card">
                                    <p style={{ marginTop: "0" }}>
                                        { t("pages.customise.3-help.label-upload") }
                                    </p>
                                    <div style={{ margin: "1.4rem 0" }}/> {/* TODO */}
                                    <div className="button-group">
                                        <button className="button stretch" type="submit" disabled={!acceptInput.value}>
                                            { t("pages.customise.3-help.button-upload") }
                                        </button>
                                    </div>
                                    <div style={{ margin: "1.4rem 0" }}/> {/* TODO */}
                                    { acceptInput.render() }
                                </div>
                            </div>
                        </div>
                    </div>

                    { props?.finalPage != null &&
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="funnel-default-card">
                                    {props?.finalPage?.()}
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                :
                <>
                    <Spinner className="absolute"/>
                </>
            }
        </>
    );
};

export default UploadForm;
