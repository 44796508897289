import { merge } from "lodash";
import useApi from "@/res/hooks/SynegaApi";

export const ACTIONS = {
    SAVE: "save",
    STRICT_SAVE: "strict-save",
    LOAD: "load",
    RESET: "reset",
    UPDATE: "update",
    DELETE: "delete",
    SET_SUBMIT: "set-submit",
    SET_EVALUATE: "set-evaluate",
    PAGE_NEXT: "page-next",
    PAGE_RETURN: "page-return",
    SET_PAGES: "set-pages",
    DESELECT_SERVICE: "deselect-service",
}

const updateClient = async (props) => {
    if ((props?.client?.version ?? 0) < 2 || (props?.shared?.reference?.length ?? 0) === 0) return;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let api = useApi(2);

    // let current_stage = `${props?.stage}/${props?.map?.length}`;
    let current_page = `${props?.page?.index + 1}/${props?.map?.[props?.stage]?.[props?.category?.index]?.[0]?.length ?? props?.map?.[props?.stage]?.length}`;

    let page = null;
    if (props?.stage === 2) {
        page = props?.map?.[props?.stage]?.[props?.category?.index]?.[0]?.[props?.page?.index];
    }

    let stateMap = props?.map?.map((entry, index) => {
        let sub_pages = null;
        let name = "ServiceConfiguration";
        if (typeof entry[0] === "function") {
            name = entry[0]().type.name;
        } else {
            sub_pages = entry[props?.category?.index]?.[0]?.map((e, i) => {
                let name = e().type.name;
                let details = { title: name };
                if (props?.stage === index && props?.page?.index === i) {
                    details.position = "Client has reached this page.";
                }
                return details;
            })
        }

        let details = { title: name };
        if (sub_pages !== null) details.sub_pages = sub_pages;
        if (props?.stage === index && index !== 2) {
            details.position = "Client has reached this page.";
        }

        return details;
    });

    let stateDescription = "";
    switch (props?.stage) {
        case 0: // Price selection
            stateDescription = "Checking out the prices."
            break;
        case 1: // Service selection
            stateDescription = "Choosing service."
            break;
        case 2: // Service configuration
            stateDescription = page?.({ flag: "STATE_DESCRIPTION" }) ?? "unknown";
            if (stateDescription === "unknown") {
                Sentry.captureMessage(`No page description. App "${props?.shared?.service_code}", category "${props?.category?.name}" at current page ${current_page}.`)
            }
            break;
        case 3: // Confirm and send
            stateDescription = "Ready to send form."
            break;
    }

    const dataClone = JSON.parse(JSON.stringify(props));
    delete dataClone.map
    delete dataClone.movement
    delete dataClone.uploading
    delete dataClone.finished
    delete dataClone.evaluate
    delete dataClone.submit

    api.patch(`/clients/${props?.shared?.reference}`, {
        operations: "update",
        state_description: stateDescription,
        state_map: stateMap,
        data: dataClone,
    }).catch(error => {
        console.log(error?.response?.data?.messages);
    });
}

export const FormReducerLite = (state, action) => {
    let updatedState = null;

    switch (action.type) {
        case ACTIONS.DELETE:
            localStorage.removeItem(state.shared.state_key);
            return { shared: state.shared };

        case ACTIONS.RESET:
            return { shared: state.shared }

        case ACTIONS.LOAD:
            let payload = JSON.parse(localStorage.getItem(state.shared.state_key));
            return { ...payload }

        case ACTIONS.UPDATE:
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);
            return updatedState;

        case ACTIONS.SAVE:
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);
            localStorage.setItem(state.shared.state_key, JSON.stringify(updatedState));
            return updatedState;

        default:
            throw new Error("Unknown reducer action: " + action.type);
    }
};

const FormReducer = (state, action) => {
    let updatedState = null;
    let payload = null;
    let map = null;
    let page_index = null;

    switch (action.type) {
        case ACTIONS.DELETE:
            localStorage.removeItem(state.shared.client_payload_key);
            return { shared: state.shared };

        case ACTIONS.RESET:
            return { shared: state.shared }

        case ACTIONS.LOAD:
            payload = JSON.parse(localStorage.getItem(state.shared.client_payload_key));
            return { ...payload }

        case ACTIONS.UPDATE:
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);
            return updatedState;

        case ACTIONS.SAVE:
            payload = action?.payload ?? {};
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);

            map = updatedState.map;
            updatedState = { ...updatedState, map: null }
            localStorage.setItem(state.shared.client_payload_key, JSON.stringify(updatedState));
            updatedState = { ...updatedState, map: map }
            return updatedState;

        case ACTIONS.STRICT_SAVE:
            payload = action?.payload ?? {};
            updatedState = { ...state, ...payload };

            map = updatedState.map;
            updatedState = { ...updatedState, map: null }
            localStorage.setItem(state.shared.client_payload_key, JSON.stringify(updatedState));
            return { ...updatedState, map: map };

        case ACTIONS.PAGE_NEXT:
            if ((state?.evaluate?.() ?? true) === false && ((action?.force ?? false) === false)) {
                return state;
            }

            if (state?.stage < 2 || (state?.page?.index + 1 >= (state?.map?.[2]?.[state?.category?.index]?.[state?.stage - 2]?.length ?? 0))) {
                updatedState = { ...state, stage: state.stage + 1, page: { index: 0 }};
            } else {
                updatedState = { ...state, page: { index: state?.page?.index + 1 }};
            }

            // Clean up submission and evaluation functions
            map = updatedState.map;
            updatedState = { ...updatedState, map: null, evaluate: null, submit: null, movement: 1 }
            localStorage.setItem(state.shared.client_payload_key, JSON.stringify(updatedState));
            updatedState = { ...updatedState, map: map }
            updateClient(updatedState);
            return updatedState;

        case ACTIONS.PAGE_RETURN:
            if (state.page.index > 0) {
                updatedState = { ...state, page: { index: state.page.index - 1 }}
            } else {
                if (state?.stage > 2) {
                    page_index = (state?.map?.[2]?.[state?.category?.index]?.[state?.stage - 3]?.length ?? 1) - 1
                } else {
                    page_index = 0;
                }
                updatedState = { ...state, stage: state.stage - 1, page: { index: page_index }};
            }

            // Clean up submission and evaluation functions
            map = updatedState.map;
            updatedState = { ...updatedState, map: null, evaluate: null, submit: null, movement: -1 }
            localStorage.setItem(state.shared.client_payload_key, JSON.stringify(updatedState));
            updatedState = { ...updatedState, map: map }
            return updatedState;

        case ACTIONS.SET_SUBMIT:
            updatedState = { ...state }
            if (updatedState.submit == null) {
                updatedState = { ...updatedState, submit: action.submit }
            }

            return updatedState;

        case ACTIONS.SET_EVALUATE:
            updatedState = { ...state, evaluate: action.evaluate };
            return updatedState;

        case ACTIONS.SET_PAGES:
            return { ...state, map: action.map, service_props: action.service_props };

        case ACTIONS.DESELECT_SERVICE:
            return { ...state, stage: 1, page: { index: 0 }, category: null, movement: -1 }

        default:
            throw new Error("Unknown reducer action: " + action.type);
    }
};

export default FormReducer
