import { IS_SERVER } from "@/res/helpers/ServiceHelper";
import GenericReducer, { ACTIONS } from "@/res/reducers/generic";
import { collapseData } from "@/helpers/utility";
import { useMemo, useReducer } from "react";

const initialArgs = ({ load, ...args }) => {
    const key = args?.storageKey ?? "app";
    const storage = args?.storage ?? "session";
    const app = args?.app ?? null;

    let data = {}
    if (!IS_SERVER && load) {
        const storageClass = storage === "local" ? localStorage : sessionStorage;
        data = JSON.parse(storageClass.getItem(key) ?? "{}");
    }

    return {
        key: key,
        storage: storage,
        user: null,
        app: app,
        conditions: {
            disabled: false,
            locked: false,
            submitted: false,
            downloading: false,
            uploading: false,
        },
        ...data,
    };
}

const useContextAbstraction = ({ behaviour = "save", ...args }) => {
    const [state, dispatch] = useReducer(GenericReducer, initialArgs({ load: true, ...args }));
    const keys = ["key", "storage", "user", "conditions"];

    const isLoggedIn = useMemo(() => {
        if (state?.user?.token?.length > 0) {
            return true;
        }

        return false;
    }, [state.user, state.user?.token]);

    const logOff = () => {
        dispatch({ type: ACTIONS.LOGOUT });
    };

    // Resets the state back to only including the default keys.
    const reset = async () => {
        dispatch({
            type: ACTIONS.OVERWRITE,
            payload: initialArgs({ app: state?.app, ...args }),
        })
    }

    // Fetches all keys from the state except for the default keys.
    const payload = (excludeKeys = []) => {
        const excludedKeys = keys + excludeKeys;
        let _payload = Object.keys(state).reduce((acc, key) => {
            if (!excludedKeys.includes(key)) {
                acc[key] = state[key];
            }
            return acc;
        }, {});

        return collapseData(structuredClone(_payload));
    }

    const simpleDispatch = (payload, strict = false) => {
        switch (behaviour) {
            case "save":
                saveDispatch(payload, strict);
                return;
            case "update":
                updateDispatch(payload, strict);
                return;
            default:
                throw new Error(`Unhandled behaviour (${behaviour})`);
        }
    }

    const saveDispatch = (payload, strict = false) => {
        dispatch({
            payload,
            type: strict ? ACTIONS.STRICT_SAVE : ACTIONS.SAVE,
        })
    }

    const updateDispatch = (payload, strict = false) => {
        dispatch({
            payload,
            type: strict ? ACTIONS.STRICT_UPDATE : ACTIONS.UPDATE,
        })
    }

    const dynamicStorage = () => {
        return (args?.storage ?? "local") === "local" ? localStorage : sessionStorage;
    }

    return {
        state,
        dispatch,
        keys,

        saveDispatch,
        simpleDispatch,
        updateDispatch,
        dynamicStorage,
        actions: ACTIONS,
        defaultAction: behaviour === "save" ? ACTIONS.SAVE : ACTIONS.UPDATE,
        isLoggedIn,
        logOff,

        reset,
        payload,
    }
}

export default useContextAbstraction;
