import React, { useEffect } from "react";
import useSelectionInput, { SelectionInput } from "@/hooks/form/selection-input";
import { useMainContext } from "@/res/context/main";
import useUsers from "@/hooks/users";
import NonSsrWrapper from "@/components/wrappers/non-ssr-wrapper";
// import { Trans } from "@lingui/macro";

export const COUNTRIES = [
    {
        label: <>Velg land</>,
    },
    {
        label: <>Norge</>,
        value: "no",
    },
];

export const SERVICES = [
    {
        label: <>Velg tjeneste</>,
    },
    {
        label: <>Hovedkontor</>,
        value: "hq",
    },
    {
        label: <>Advokat</>,
        value: "le",
    },
    {
        label: <>Regnskapsføring</>,
        value: "ac",
    },
    {
        label: <>Revisor</>,
        value: "au",
    },
];

const Topbar = (props) => {
    const context = useMainContext();
    const { state, simpleDispatch } = context;

    const country = useSelectionInput({
        context, key: "tms.country",
        options: COUNTRIES,
        default: 1,
        disabled: true,
    })
    const service = useSelectionInput({
        context, key: "tms.service",
        options: SERVICES,
    })

    const { users } = useUsers({
        label: ["HQ", "HQNO", "undefinedNO", undefined, null].includes(state.app) ?
            "Velg en tjeneste først" :
            "Velg deg selv",
    });
    const user = useSelectionInput({
        context, key: "tms.user",
        options: users,
        disabled: ["HQ", "HQNO", "undefinedNO", undefined, null].includes(state.app),
    })

    useEffect(() => {
        if (!user.value?.id) return;
        if (user.value.app === state.app) return;
        simpleDispatch({ tms: { user: null }});
    }, [state.app])

    useEffect(() => {
        const _country = country?.value?.value;
        const _service = service?.value?.value;
        if (_service === undefined || _country === undefined) return;

        if (!_service || !_country) {
            simpleDispatch({ app: null })
            return;
        }

        simpleDispatch({ app: `${_service}${_country}`.toUpperCase() })
    }, [country?.value?.value, service?.value?.value]);

    return (
        <nav className="nav__topbar">
            <NonSsrWrapper>
                <SelectionInput {...country.props}/>
                <SelectionInput {...service.props}/>
                <SelectionInput {...user.props}/>
            </NonSsrWrapper>
            <span style={{
                color: "#244a66",
                fontWeight: "800",
                fontSize: "1.5rem",
                lineHeight: "40px",
                marginLeft: "1rem",
                display: "none",
            }}>
                DEMO
            </span>
        </nav>
    );
};

export default Topbar;
