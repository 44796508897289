import React, { useEffect, useState } from "react";
import useForm from "./useForm";
import { resolvePath } from "../../helpers/ObjectHelpers";

import InfoSVG from "@/res/assets/icons/questionmark.svg";
import TooltipSVG from "@/res/assets/icons/info.svg";
import ShowSVG from "@/res/assets/icons/eye.svg";
import HideSVG from "@/res/assets/icons/hide.svg";

const useStringInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const [mask, setMask] = useState(true);
    const [direction, setDirection] = useState("");
    const [visible, setVisibility] = useState("");
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "stringInput";
    const elementId = `useStringInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePath(context?.state, key) : values[key];

    // TODO temporary band-aid fix to placeholder attribute not updating itself based on translation string.
    useEffect(() => {
        if (value === undefined && (props?.default !== undefined || rule?.default !== undefined)) {
            setValue(props?.default !== undefined ? props?.default : rule?.default);
        }

        let el = document.getElementById(elementId);
        el?.setAttribute?.("placeholder", props?.placeholder ?? "")
    }, []);

    const getClient = (e) => {
        return {
            clientY: e.nativeEvent.toString().includes("MouseEvent") ? e.clientY : e?.targetTouches?.[0]?.clientY,
            clientX: e.nativeEvent.toString().includes("MouseEvent") ? e.clientX : e?.targetTouches?.[0]?.clientX,
        }
    };

    const onInfoBoxToggle = (e, mobileSafe = false) => {
        if (window.innerWidth < 600 && !mobileSafe && !(e?._reactName === "onMouseLeave")) {
            return false;
        }

        if (e.target.classList.contains("info-box") || e.target.classList.contains("input__tooltip") || e.target.classList.contains("visible")) {
            setVisibility("");
            return true;
        }

        return false;
    };

    const onInfoBoxHover = (e) => {
        // Sizes to be mindful of:
        // Info button is at it's largest 48px X and Y.
        // Top Navbar is at it's largest 88px.

        let client = getClient(e);
        if (window.innerWidth < 600 && (e.nativeEvent.toString().includes("MouseEvent") || onInfoBoxToggle(e, true))) {
            return;
        }

        let vertical = "top";
        let horizontal = "left";
        const room = {
            top: (window.innerHeight - (window.innerHeight - client.clientY)),
            right: (window.innerWidth - client.clientX),
            bottom: (window.innerHeight - client.clientY),
            left: (window.innerWidth - (window.innerWidth - client.clientX)),
        }

        if (room.left < room.right && room.left < 300 + 60) {
            horizontal = "right";
        }

        if (room.top < room.bottom && room.top < 500 + 60) {
            vertical = "bottom";
        }

        setDirection(` ${vertical} ${horizontal}`);
        setVisibility(" visible");
    };

    const handleChangeMiddleman = (e) => {
        if (e?.target?.value && rule?.numbers_only) {
            e.target.value = e.target.value.replace(/\D/g, "");
        }

        handleChange(e)
        isValid(e?.target?.value);
    }

    const setValue = (value) => {
        forceValue(key, value);
    };

    const setInvalidity = (message) => {
        let item = document.getElementById(elementId);
        item?.setCustomValidity?.(message);
    }

    const isValid = (v = null) => {
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;

        if (props?.onValidation != null) {
            let response = props?.onValidation?.(item, _value);
            if (response === true || response === false) return response;
            return true;
        }

        item?.setCustomValidity?.("");
        return true;
    }

    const render = (inputOnly = false) => {
        if (inputOnly) {
            return (
                <input
                    id={ elementId }
                    type={ (rule?.password && mask) ? "password" : "text" }
                    name={ key }
                    placeholder={ props?.placeholder ?? "" }
                    value={ value ?? "" }
                    onChange={ props?.onChange ?? handleChangeMiddleman}
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    minLength={ rule?.minLength }
                    maxLength={ rule?.maxLength }
                    required={ rule?.required ?? true }
                    className={ props?.inputClass ?? props?.class }
                    autoComplete={ rule?.autoComplete ?? "name" }
                    disabled={ rule?.disabled ?? false }
                />
            );
        }

        return (
            <div>
                { props?.label &&
                <label className="label__tooltip" htmlFor={elementId}>
                    {props?.label} { props?.tooltip &&
                    <div
                        className={ "input__t-container" + visible }
                        onMouseEnter={onInfoBoxHover}
                        onMouseLeave={onInfoBoxToggle}
                        onTouchStart={onInfoBoxHover}
                        onFocus={onInfoBoxToggle}
                    >
                        <img src={TooltipSVG} alt=""/>
                        <div className={"input__tooltip" + direction} dangerouslySetInnerHTML={{ __html: props?.tooltip }} />
                    </div>
                    }
                </label>
                }
                <div className={`input-container ${(props?.suffix ?? false) ? "suffix" : ""} ${((props?.prefix || rule?.password) ?? false) ? "prefix" : ""} ${props?.class}`}>
                    { (props?.prefix && !rule?.password) &&
                        <div className={`prefix-addon ${ props?.available === true ? "available" : props?.available === false ? "not-available" : ""}`}>
                            {props?.prefix}
                        </div>
                    }
                    { (!props?.prefix && rule?.password) &&
                        <div className="prefix-addon" password={ rule?.password } onMouseDown={() => setMask(!mask)} onMouseUp={() => setMask(true)}>
                            <img src={mask ? HideSVG : ShowSVG} alt="" loading="lazy"/>
                        </div>
                    }
                    <input
                        id={ elementId }
                        type={ (rule?.password && mask) ? "password" : "text" }
                        name={ key }
                        placeholder={ props?.placeholder ?? "" }
                        value={ value ?? "" }
                        onChange={ props?.onChange ?? handleChangeMiddleman}
                        onKeyUp={ props?.onKeyUp }
                        onFocus={ props?.onFocus }
                        minLength={ rule?.minLength }
                        maxLength={ rule?.maxLength }
                        required={ rule?.required ?? true }
                        className={ props?.inputClass ?? props?.class }
                        autoComplete={ rule?.autoComplete ?? "name" }
                        disabled={ rule?.disabled ?? false }
                    />
                    { props?.suffix &&
                        <div className={ "suffix-addon" +
                            (props?.available == null ? "" : props?.available ? " available" : " not-available") +
                            (props?.info ? " info-present" : "")
                        }>
                            {props?.suffix}
                        </div>
                    }
                    { props?.info &&
                        <div
                            className={"info-addon" + visible}
                            onMouseEnter={onInfoBoxHover}
                            onMouseLeave={onInfoBoxToggle}
                            onTouchStart={onInfoBoxHover}
                        >
                            <img src={InfoSVG} alt=""/>
                            <div className={"info-box" + direction} dangerouslySetInnerHTML={{ __html: props?.info }} />
                        </div>
                    }
                </div>
            </div>
        );
    };

    return {
        value, render, isValid, setValue, setInvalidity,
    };
};

export default useStringInput;
