import React, { useContext, useEffect, useState } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useStringInput from "@/res/hooks/Form/useStringInput";
import { useTranslation } from "react-i18next";
import useNumberInput from "@/res/hooks/Form/useNumberInput";
import usePhoneInput from "@/res/hooks/Form/usePhoneInput";
import useEmailInput from "@/res/hooks/Form/useEmailInput";
import useHiddenInput from "@/res/hooks/Form/useHiddenInput";

const Associate = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();
    const index = props?.index;

    const isOwnerInput = useHiddenInput({
        context: serviceContext, key: `config.shareholders.${index}.id`,
    });

    const contactPerson = useHiddenInput({
        context: serviceContext, key: "config.contact_person",
    });

    const [owner, setOwner] = useState(
        (isOwnerInput?.value >= 0 || isOwnerInput?.value?.length >= 0) && isOwnerInput?.value != null ? true :
            contactPerson?.value == null ? null : false
    );

    const nameInput = useStringInput({
        context: serviceContext, key: `config.shareholders.${index}.name`, class: "small",
        label: t("_shared.form.name-label"), placeholder: t("_shared.form.name-placeholder"),
    });
    const emailInput = useEmailInput({
        context: serviceContext, key: `config.shareholders.${index}.email`, class: "small",
        label: t("_shared.form.email-label"), placeholder: t("_shared.form.email-placeholder"),
    });
    const phoneInput = usePhoneInput({
        context: serviceContext, key: `config.shareholders.${index}.phone`, class: "small",
        label: t("_shared.form.phone-label"),
    });
    const shareInput = useNumberInput({
        context: serviceContext, key: `config.shareholders.${index}.share`, class: "small",
        rule: {
            required: !serviceContext?.state?.client?.is_sales,
            minValue: (serviceContext?.state?.client?.is_sales ? 0 : 1), maxValue: 100,
        },
        label: t("pages.customise.2-service.associates.ownership-label"),
    })

    useEffect(() => {
        if (index !== 0 || contactPerson?.value != null && (owner == null || owner)) return;

        if (serviceContext?.state?.client?.version >= 2) {
            // TODO there is some problem that causes the email to lose its ID when navigating back to this page, issue is mostly unknown

            let cp = serviceContext?.state?.client?.people?.find(item => {
                return item?.is_contact_person;
            });
            contactPerson.setValue({
                id: cp?.id,
                name: cp?.name,
                phone: `${cp?.phone_numbers?.[0]?.dial_code}${cp?.phone_numbers?.[0]?.number}`,
                // phone: {
                //     id: cp?.phone_numbers?.[0]?.id,
                //     number: cp?.phone_numbers?.[0]?.number,
                //     dial_code: cp?.phone_numbers?.[0]?.dial_code,
                // },
                email: {
                    id: cp?.emails?.[0]?.id,
                    address: cp?.emails?.[0]?.address,
                },
            });
        } else {
            let cp = serviceContext?.company?.people?.[0];
            contactPerson.setValue({
                id: cp?.id,
                name: cp?.name,
                dial_code: cp?.dial_code,
                phone: cp?.mobile,
                email: cp?.email,
            })
        }
    }, [owner])

    useEffect(() => {
        if (index !== 0 || !owner || contactPerson?.value == null) return;

        if (serviceContext?.state?.client?.version >= 2) {
            contactPerson.setValue({
                id: contactPerson?.value?.id,
                name: nameInput?.value,
                phone: phoneInput?.value,
                email: emailInput?.value,
            });
        } else {
            contactPerson.setValue({
                name: nameInput?.value,
                dial_code: phoneInput?.value?.dial_code,
                phone: phoneInput?.value?.number,
                email: emailInput?.value,
            })
        }
    }, [nameInput?.value, phoneInput?.value?.number, shareInput?.value, emailInput?.value])

    useEffect(() => {
        if (index !== 0 || owner == null || contactPerson?.value == null) return;

        if (owner) {
            isOwnerInput.setValue(contactPerson?.value?.id)
            nameInput.setValue(contactPerson?.value?.name ?? "");

            if (serviceContext?.state?.client?.version >= 2) {
                phoneInput.setValue(contactPerson?.value?.phone);
                emailInput.setValue(contactPerson?.value?.email);
            } else {
                emailInput.setValue(contactPerson?.value?.email ?? "");
                phoneInput.setValue(contactPerson?.value?.dial_code + contactPerson?.value?.phone);
            }
        } else {
            isOwnerInput.setValue(null);
        }
    }, [owner, contactPerson?.value])

    return (
        <>
            <div className="associate-container">
                { index === 0 &&
                <div className="toggle-container" onClick={() => setOwner(!owner)}>
                    <label>
                        {t("pages.customise.2-service.associates.is-contact-person-signee-label")}
                    </label>
                    <div className={"toggle-box" + (owner ? " selected" : "")}>
                        <span/>
                    </div>
                </div>
                }
            </div>
            {nameInput.render()}
            <div className="input-group">
                {emailInput.render()}
                {phoneInput.render()}
            </div>
            {shareInput.render()}
        </>
    );
};

export default Associate;
