import { merge } from "lodash";

export const ACTIONS = {
    LOGOUT: "logout",
    LOAD: "load",
    STRICT_SAVE: "strict-save",
    SAVE: "save",
    STRICT_UPDATE: "strict-update",
    UPDATE: "update",
    OVERWRITE: "overwrite",
    CLEAR: "clear",
};

const GenericReducer = (state, action) => {
    const STATE_KEY = state?.key ?? "generic-form";
    const ACTION_TYPE = action?.type ?? ACTIONS.UPDATE;

    const storageClass = state?.storage === "local" ? localStorage : sessionStorage;
    let updatedState = null;

    switch (ACTION_TYPE) {
        case ACTIONS.LOGOUT:
            updatedState = { ...state, user: null };
            return updatedState;

        case ACTIONS.LOAD:
            let payload = JSON.parse(storageClass.getItem(STATE_KEY));
            return { ...payload };

        case ACTIONS.STRICT_SAVE:
            updatedState = { ...state, ...action.payload };
            storageClass.setItem(STATE_KEY, JSON.stringify(updatedState));
            return updatedState;

        case ACTIONS.SAVE:
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);
            storageClass.setItem(STATE_KEY, JSON.stringify(updatedState));
            return updatedState;

        case ACTIONS.STRICT_UPDATE:
            updatedState = { ...state, ...action.payload };
            return updatedState;

        case ACTIONS.UPDATE:
            updatedState = { ...state };
            updatedState = merge(updatedState, action.payload);
            return updatedState;

        case ACTIONS.OVERWRITE:
            storageClass.setItem(STATE_KEY, JSON.stringify({ ...action.payload }));
            return { ...action.payload };

        case ACTIONS.CLEAR:
            return {
                isDevelopment: state?.isDevelopment,
            };

        default:
            throw new Error("Unknown reducer action: " + (ACTION_TYPE ?? "ACTION NOT PROVIDED"));
    }
};

export default GenericReducer;
