import { IS_SERVER } from "@/res/helpers/ServiceHelper";

export const LOCALE_REDIRECTS = [
    ["/no/", "asPath", "no-no"],
    ["/en/", "asPath", "en-no"],
];

// These can eventually be removed.
const LEGACY_REDIRECTS = [
    ["/regnskapsfoerer/esign", "external", "https://web.synega.com/esign"],
    ["/revisor/esign", "external", "https://web.synega.com/esign"],
    ["/advokat/esign", "external", "https://web.synega.com/esign"],

    ["/regnskapsfoerer", "/legacy/regnskapsfoerer"],
    ["/revisor", "/legacy/revisor"],
    ["/advokat", "/legacy/advokat"],
    ["/legacy", "/legacy"],
];

// Miscellaneous redirects
const MISC_REDIRECTS = [
    ["/veis", "/veis/"],
];

export const REDIRECTS = [
    ...LEGACY_REDIRECTS,
    ...MISC_REDIRECTS,
    ["/tms", "/tms"],
    ["/tsm", "/tms"],

    // ["/demo/test", null],
    // ["/demo", "/demo"],
]

const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const redirect = (router, list) => {
    for (let redirect of list) {
        let regex = new RegExp(`^${escapeRegExp(redirect[0])}`)
        let regex2 = new RegExp(`^\\/[a-z]{2}-[a-z]{2}${escapeRegExp(redirect[0])}`)

        if (regex.test(router.asPath) || regex2.test(router.asPath)) {
            let search = router.asPath.split("?");
            search = search.length > 1 ? `?${search[1]}` : ""

            if (redirect[1] === "asPath") {
                router.replace(router.asPath.replace(redirect[0], `/${redirect[2]}/`))
                return true;
            } else if (redirect[1] === "external") {
                router.replace(redirect[2] + search);
                return true;
            } else if (redirect[1] !== null) {
                let path = router.asPath.split(redirect[0])[0];
                if (!path.includes(router.locale)) path = `/${router.locale}` + path;

                router.replace(path + redirect[1] + search);
                return true;
            }

            break;
        }
    }

    return false;
}

export const localeRedirect = (router) => {
    let path = (IS_SERVER ? router.asPath : window.location.pathname)
        .split("/").filter(str => str?.length > 0);

    if (path.length === 0) {
        router.replace(router.locale);
        return true;
    } else if (path[0].length === 2) {
        return redirect(router, LOCALE_REDIRECTS);
    } else if (!router.locales.includes(path[0])) {
        let pattern = /^\/[a-z]{2}-[a-z]{2}/;
        let pattern2 = /\/\[locale\]/;

        if (pattern.test(router.asPath)) {
            router.replace("/" + router.asPath.replace(pattern, router.locale));
            return true;
        } else if (pattern2.test(router.asPath)) {
            router.replace("/" + router.asPath.replace(pattern2, router.locale));
            return true;
        } else {
            router.replace(`/${router.locale}` + router.asPath);
            return true;
        }
    }

    return false;
}

export default redirect;
