import React from "react";
import { innerText } from "@/helpers/utility";
import Tooltip from "@/components/tooltip";

const Label = ({ children, label, subLabel, tooltip, id, required }) => {
    if (!label) return null;

    return (
        <label htmlFor={id} title={innerText(label) + (required ? " (Obligatorisk)" : "")}>
            { tooltip && <Tooltip className="md-hidden--hard lg-hidden--hard">{ tooltip }</Tooltip> }
            { children ?? label } { required && <span><small>(Obligatorisk)</small></span> }
            { subLabel &&
                <div>
                    <small>
                        { subLabel }
                    </small>
                </div>
            }
            { tooltip && <Tooltip className="ml-1 sm-hidden--hard">{ tooltip }</Tooltip> }
        </label>
    )
};

export default Label;
