import React from "react";
import useApi from "@/res/hooks/SynegaApi";
import Lede from "../services/de/lede";
import Sein from "../services/in/sein";
import Acus from "../services/us/acus";
import Leno from "../services/no/leno";
import Acno from "../services/no/acno";
import Auno from "../services/no/auno";
import PriceSelection from "@/res/components/PriceSelection";
import ServiceSelection from "@/res/components/ServiceSelection";
import UploadForm from "@/res/components/ClientCustomise/UploadForm";

export const IS_SERVER = typeof window === "undefined";
export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const STORAGE_KEY = (service_code) => {
    let code = (service_code ?? findService())?.toLowerCase() ?? "";

    return {
        APPLICANT: (code ?? "") + "-" + "applicant",
        CONSULTANT: (code ?? "") + "-" + "consultant",
        CONSULTANT_ID: (code ?? "") + "-" + "consultant-id",
        COMPANY: (code ?? "") + "-" + "company",
        CLIENT_ID: (code ?? "") + "-" + "client-id",
        CLIENT_CREATE: (code ?? "") + "-" + "client-create",
        PAYLOAD: (code ?? "") + "-" + "client-payload",
    }
};

export const findService = (country = null, service = null, throwable = true) => {
    if (country == null || service == null) {
        if (IS_SERVER) {
            return "";
        }

        let url = evaluateUrl();
        country = url.country;
        service = url.service;
    }

    let countryCode = country.toUpperCase();
    let serviceCode = "";

    switch (service) {
        case "services":
            serviceCode = "SE";
            break;
        case "advokat":
        case "legal":
            serviceCode = "LE";
            break;
        case "regnskapsfoerer":
        case "accounting":
            serviceCode = "AC";
            break;
        case "revisor":
        case "auditing":
            serviceCode = "AU";
            break;
        case "management-consulting":
        case "ledelsesraadgiver":
            serviceCode = "MC"
            break;
        case "crm":
        case "crm/enterprise":
        case "global":
            serviceCode = "HQ"
            break;
        default:
            if (throwable) {
                throw new Error("Unknown service: " + service);
            }
            break;
    }

    return serviceCode + countryCode;
}

export const homeUrl = (country) => {
    switch (country.toLowerCase()) {
        case "gb":
        case "uk":
            return "https://synega.co.uk/"
        case "no":
            return "https://synega.no/"
        case "in":
            return "https://synega.in/"
        case "de":
            return "https://synega.de/"
        case "us":
            return "https://synega.com/"
    }
}

export const privacyPolicyUrl = (country) => {
    switch (country.toLowerCase()) {
        case "gb":
        case "uk":
            return "https://synega.co.uk/privacy"
        case "no":
            return "https://synega.no/personvern/"
        case "in":
            return "https://synega.in/privacy/"
        case "de":
            return "https://de.wp.synega.com/privacy"
        case "us":
            return "https://synega.com/privacy/"
    }
}

export const evaluateUrl = () => {
    let country = "no"; // legacy is only available in norway
    // let country = window.location.pathname.split("/")[1];
    // if (country?.length > 2) {
    //     country = country.split("-")[1]
    // }

    let service = window.location.pathname.split("/")[2];
    if (service?.length === 2 || service.toLowerCase() === "legacy") {
        service = window.location.pathname.split("/")[3];
    }

    return { country, service };
};

export const initialiseFormPayload = (props) => {
    if (IS_SERVER) return;
    let serviceCode = findService(props?.country, props?.service).toLowerCase();
    let storageKeys = STORAGE_KEY(serviceCode);

    let extra = null;
    let payload = null;
    if (props?.initial) {
        payload = JSON.parse(localStorage.getItem(storageKeys.CLIENT_CREATE));
    } else {
        extra = {
            submit: null,
            evaluate: null,
            movement: null,
            uploading: false, // TODO temporarily until reloading of page will check API progress
            finished: false, // TODO?
            client: clientDetails(storageKeys.COMPANY),
        };

        payload = JSON.parse(localStorage.getItem(storageKeys.PAYLOAD));
        if (payload == null) {
            payload = {
                stage: 0,
                page: { index: 0 },
                shared: {
                    reference: extra?.client?.id,
                },
                // uploading: false, TODO reinstate
            };
        }
    }

    return {
        ...(payload ?? {}),
        ...(extra ?? {}),
        shared: {
            version: "5",
            reference: payload?.shared?.reference ?? "",
            service_code: serviceCode.toUpperCase(),
            state_key: storageKeys.CLIENT_CREATE,
            company_key: storageKeys.COMPANY,
            client_create_key: storageKeys.CLIENT_CREATE,
            client_payload_key: storageKeys.PAYLOAD,
            client_id_key: storageKeys.CLIENT_ID,
        },
    };
};

// In development, because of React Strict Mode, this will likely run twice.
export const initialiseClient = async (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const api = useApi(2);
    let reference = "";

    await api.post("/clients", {
        app: props?.shared?.service_code,
        assisted_by: props?.assistedBy,
    }).then(res => {
        if (res?.data?.reference?.length > 0) {
            reference = res?.data?.reference;
        }
    }).catch(error => {
        console.log(error?.response?.data?.messages);
    });
    return reference;
}

export const serviceObject = (service_code) => {
    let service = null;

    switch (service_code) {
        case "LEDE":
            service = Lede;
            break;
        case "SEIN":
            service = Sein;
            break;
        case "ACUS":
            service = Acus;
            break;
        case "LENO":
            service = Leno;
            break;
        case "ACNO":
            service = Acno;
            break;
        case "AUNO":
            service = Auno;
            break;
        default:
            throw new Error(`Couldn't find any service with code: ${service_code}`);
    }

    let stages = service?.props?.()?.services?.map(s => {
        return s?.stages;
    });
    if (stages == null) {
        stages = [];
    }

    let map = [
        [
            (props) => {
                return <PriceSelection {...props}/>
            },
        ],
        [
            (props) => {
                return <ServiceSelection {...props}/>
            },
        ],
        [
            ...stages,
        ],
        [
            (props) => {
                return <UploadForm {...props}/>
            },
        ],
    ];

    return { service, map };
};

const clientDetails = (client_key) => {
    let client = JSON.parse(localStorage.getItem(client_key)); // TODO

    if (client?.version === 2) {
        // TODO anything else important missing? What about the [extra] field?

        return {
            version: client?.version,
            id: client?.id,
            name: client?.name,
            is_sales: (client?.assisted_by === "SALES") ?? false,
            is_business: (client?.category === "BUSINESS") ?? false,
            people: client?.people,
            company: client?.company,
        }
    }

    // Everything below here is deprecated, but some services outside NO are still using them.
    if (!client?.isBusiness ?? false) {
        return {
            name: client?.name,
            is_sales: client?.isSales ?? client?.sales_funnel ?? false,
            is_business: client?.isBusiness ?? false,
            id: client?.id,
            extra: client?.extra,
            email: client?.email,
            phone: {
                dial_code: client?.dial_code,
                number: client?.mobile_number,
            },
        };
    }

    return {
        name: client?.name,
        is_sales: client?.isSales ?? client?.sales_funnel ?? false,
        is_business: client?.isBusiness ?? false,
        id: client?.id_token,
        extra: client?.extra,
        contact_person: {
            id: client?.people?.[0]?.id,
            name: client?.people?.[0]?.name,
            email: client?.people?.[0]?.email,
            phone: {
                dial_code: client?.people?.[0]?.dial_code,
                number: client?.people?.[0]?.mobile,
            },
        },
    };
}
