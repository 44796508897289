import React, { useEffect, useState } from "react";
import useApi from "@/res/hooks/SynegaApi";
import { useMainContext } from "@/res/context/main";

const useUsers = (props) => {
    const api = useApi(3);

    const { label } = props ?? {};
    const context = useMainContext();
    const { state } = context;

    const [users, setUsers] = useState([ { label: label ?? "Velg fra listen" } ])
    useEffect(async () => {
        let users = [{ label: label ?? "Velg fra listen" }];
        if (["HQ", "HQNO", "undefinedNO", undefined].includes(state.app) || (state.app?.length ?? 0) === 0) {
            setUsers(users);
            return;
        }

        await api.get(`/tms/users/${state.app}`).then(res => {
            if (res?.status !== "success") return;
            if ((res?.data?.length ?? 0) === 0) return;
            for (let index in res.data) {
                const user = res.data[index];
                users.push({
                    id: user.id,
                    label: user.name,
                    app: user.app,
                })
            }
        }).catch(console.warn)
        setUsers(users);
    }, [state.app])

    return {
        users,
    }
};

export default useUsers;
