import React, { createContext, useContext, useMemo } from "react";
import useContextAbstraction from "@/res/context/context-abstraction";

const MainContext = createContext();

export const MainWrapper = ({ children, ...props }) => {
    const context = useContextAbstraction(props);

    const contextValue = useMemo(() => {
        return context;
    }, [context.state, context.dispatch]);

    return (
        <MainContext.Provider value={contextValue}>
            { children}
        </MainContext.Provider>
    );
};

export function useMainContext() {
    return useContext(MainContext);
}
