import React, { useContext, useEffect } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import { Trans, useTranslation } from "react-i18next";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const AcceptTerms = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    const acceptTermsInput = useToggleInput({
        context: serviceContext, key: "config.accept_terms",
        label: t("pages.customise.2-service.accept-terms.button"),
    });

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [])

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [acceptTermsInput.value, serviceContext?.state?.config])

    const evaluate = () => {
        return acceptTermsInput.value;
    };

    return (
        <>
            { pageTitle("The terms") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    {t("pages.customise.2-service.accept-terms.header")}
                                </h5>
                                <p>
                                    <Trans i18nKey={"pages.customise.2-service.accept-terms.description"}>
                                        <a href={t("pages.customise.2-service.accept-terms.url")} target="_blank" rel="noreferrer"/>
                                    </Trans>
                                </p>
                                {acceptTermsInput.render()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcceptTerms;
