import React, { useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import Toast from "@/res/helpers/toast";
// import { i18n } from "@lingui/core";
// import { I18nProvider } from "@lingui/react";
import { changeLanguage } from "@/res/helpers/i18n";
import { responseInterceptor } from "@/res/components/axios/responseInterceptor";
import "@/res/helpers/i18n";
import "@/res/styles/application.scss";
// import useLinguiInit from "@/res/hooks/lingui";
import redirect, { LOCALE_REDIRECTS, localeRedirect, REDIRECTS } from "@/helpers/redirect";
import VeisComponentWrapper from "@/components/route-wrappers/veis";
import TmsComponentWrapper from "@/components/route-wrappers/tms";
import LegacyComponentWrapper from "@/components/route-wrappers/legacy";
import DefaultComponentWrapper from "@/components/route-wrappers/default";
import useRouter from "@/res/components/router";
import { IS_SERVER } from "@/res/helpers/ServiceHelper";

global.Sentry = Sentry;
global.Toast = Toast;

function App(props) {
    // useLinguiInit({ _locale: pageProps.country, messages: pageProps.translation })
    const router = useRouter();
    const notFound =
        router.asPath.includes("/404") || router.pathname.includes("/404") ||
        (router.pathname.length === 1 && router.asPath.length > 1);

    useEffect(() => {
        // let paths = window.location.pathname.split("/");
        // if (paths?.length > 1 && paths[1].length > 0) {
        //     changeLanguage(paths[1]);
        //     // i18n.activate(paths[1]);
        // } else {
        //     changeLanguage("no");
        //     // i18n.activate("no");
        // }

        localeRedirect(router)
        if (notFound) redirect(router, [...LOCALE_REDIRECTS, ...REDIRECTS]);
    });

    if (/^\/[a-z]{2}-[a-z]{2}\/legacy/.test(router?.asPath ?? "") && !notFound) {
        return <LegacyComponentWrapper {...props}/>;
    }

    if (/^\/[a-z]{2}-[a-z]{2}\/tms/.test(router?.asPath ?? "") && !notFound) {
        return <TmsComponentWrapper {...props}/>;
    }

    if (/^\/[a-z]{2}-[a-z]{2}\/veis/.test(router?.asPath ?? "") && !notFound) {
        return <VeisComponentWrapper {...props}/>;
    }

    return <DefaultComponentWrapper {...props}/>;
}

export default App;

axios.interceptors.response.use(responseInterceptor);
