import React from "react";
import DescribeAndSelect from "@/res/components/ServicePages/DescribeAndSelect";
import DescribeHelp from "@/res/components/ServicePages/DescribeHelp";

const priceData = (props) => [
    {
        type: "PAYG",
        name: "Pay per minute",
        details: [{
            title: "₹99 per minute",
            description:
                <>
                    <p>
                        Pay only for the minutes you spend, and not per quarter increments.
                    </p>
                </>,
        }],
    },
    {
        type: "FIXED",
        name: "Fixed price",
        details: [{
            title: "By appointment",
            description:
                <>
                    <p>
                        Get a non-binding offer that can be considered before we start the work.
                    </p>
                </>,
        }],
    },
];

const categoryData = [
    {
        internal_id: 1,
        name: "Global Expansion in India",
        color: "#d898c6",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeAndSelect {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 2,
        name: "Legal & Financial Service/Reporting",
        color: "#4495a7",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeAndSelect {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 3,
        name: "Secretarial & Tax",
        color: "#f2af89",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeAndSelect {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 4,
        name: "Website & Marketing Compliances",
        color: "#3d78a4",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeAndSelect {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 5,
        name: "International Laws",
        color: "#58caa1",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeAndSelect {...props}/>
                },
            ],
        ],
    },
    {
        internal_id: 6,
        name: "Intellectual Property Laws",
        color: "#7e7dca",
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeAndSelect {...props}/>
                },
            ],
        ],
    },

    {
        internal_id: 5000,
        name: "Private",
        color: "#282828",
        for_business: false,
        has_contract: false,
        stages: [
            [
                (props) => {
                    if (props?.flag === "STATE_DESCRIPTION") return "Describing what they specifically need help with.";
                    return <DescribeHelp {...props}/>
                },
            ],
        ],
    },
];

const descriptionTags = [
    {
        internal_id: 1,
        tags: [
            "Market Research", "Advisory on Business Registration", "Setting-up Accounting Systems",
            "Business Registrations (Pvt. Ltd, LLP, Section 8)", "Registration under govt. schemes", "Industry Agreements",
            "Advisory on Govt. schemes", "Industry-wide Compliances", "Legal and Business Compliance Research",
            "Real Estate Due Diligences", "Govt. Licenses",
        ],
    },
    {
        internal_id: 2,
        tags: [
            "Agreement Drafting/Review", "Petition Drafting/Review", "Indemnity/Affidavits Drafting", "Legal Due Diligence",
            "Business Due Diligence", "Legal Advisory", "Employment Policies", "POSH Consulting & Compliances",
            "Legal Notices & Replies", "Mergers & Acquisitions", "MIS/Issue Trackers", "Payroll Management",
            "Contract Management Solutions", "Draft Business Plans for Funding",
        ],
    },
    {
        internal_id: 3,
        tags: [
            "Incorporations (Pvt. Ltd, LLP, Section 8)", "Annual Filings", "Statutory Compliances", "Maintainence of records",
            "ROC Compliances", "Accounting and Book-keeping", "TDS Compliances", "Shareholding Advisory & Compliances",
            "Tax Complainces", "Statutory Audit", "Tax Audit", "GST Registrations & Compliance",
        ],
    },
    {
        internal_id: 4,
        tags: [
            "Data Privacy", "Website Policies", "Terms of Use", "Audit website content", "Global compliances",
        ],
    },
    {
        internal_id: 5,
        tags: [
            "Advisory", "Doing Business Globally", "Policy Drafting", "MoU with Global Firm", "Doing Business Globally",
        ],
    },
    {
        internal_id: 6,
        tags: [
            "Trademark Advisory", "Trademark Search", "Trademark Registration", "Copyright Registration", "Copyright Advisory",
        ],
    },
];

const Sein = {
    props: () => {
        return {
            prices: priceData,
            services: categoryData,
            descriptionTags: descriptionTags,
        };
    },
}

export default Sein;
