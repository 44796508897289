import React, { useContext } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const AnnualReportAuditing = (props) => {
    const serviceContext = useContext(ServiceContext);

    const annualReportToggle = useToggleInput({
        context: serviceContext, key: "config.annual_report",
        label: "Ønsker du at vi skal utarbeide årsregnskap og ligningspapirer?",
    })

    return (
        <>
            { pageTitle("Annual reporting") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    Årsregnskap og ligningspapirer
                                </h5>
                                {annualReportToggle.render()}
                                <br/>
                                <p>
                                    <small>
                                        Det kan være hensiktsmessig å la oss sette opp årsregnskap og ligningspapirer for din
                                        bedrift. Du slipper da å betale din regnskapsfører for jobben, i tillegg til at vi må
                                        kontrollere dette.
                                    </small>
                                </p>
                                <p>
                                    <small>
                                        Vi har lang erfaring, og garanterer høy kvalitet på årsoppgjørene.
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnnualReportAuditing;
