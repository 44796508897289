import React, { useContext, useEffect } from "react";
import { ServiceContext } from "@/res/context/ServiceContext";
import { Trans, useTranslation } from "react-i18next";
import useToggleInput from "@/res/hooks/Form/useToggleInput";
import { pageTitle } from "@/res/helpers/PageHelper";

const AcceptPrivacyPolicy = (props) => {
    const serviceContext = useContext(ServiceContext);
    const [t] = useTranslation();

    const acceptInput = useToggleInput({
        context: serviceContext, key: "config.accept_privacy_policy",
        label: t("pages.customise.2-service.accept-privacy-policy.button"),
    });

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [])

    useEffect(() => {
        serviceContext?.dispatch?.({ type: serviceContext?.actions?.SET_EVALUATE, evaluate: evaluate })
    }, [acceptInput.value, serviceContext?.state?.config])

    const evaluate = () => {
        return acceptInput.value;
    };

    return (
        <>
            { pageTitle("Privacy policy") }

            <div className="funnel-container funnel-container-bottom-buffer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="funnel-default-card">
                                <h5>
                                    <Trans i18nKey={"pages.customise.2-service.accept-privacy-policy.description"}>
                                        <a href={t("pages.customise.2-service.accept-privacy-policy.url")} target="_blank" rel="noreferrer"/>
                                    </Trans>
                                </h5>
                                { acceptInput.render() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcceptPrivacyPolicy;
