import React from "react";
import Head from "next/head";
import { GenericWrapper } from "@/res/context/generic";

const VeisComponentWrapper = ({ Component, pageProps }) => {
    return (
        <>
            <Head>
                <title>Arbeidsavtale - Veis.no</title>
                <link rel="icon" href="https://veis.no/wp-content/uploads/2023/01/cropped-favicon-192x192.png" sizes="192x192" />
                <link rel="apple-touch-icon" href="https://veis.no/wp-content/uploads/2023/01/cropped-favicon-180x180.png" />
            </Head>
            <GenericWrapper behaviour="update" storage="local">
                <Component {...pageProps} />
            </GenericWrapper>
        </>
    );
};

export default VeisComponentWrapper;
