import React from "react";

const DocumentsSVG = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
            <path d="M 28.5 3 C 26.019 3 24 5.019 24 7.5 L 24 8.5 L 27 8.5 L 27 7.5 C 27 6.673 27.673 6 28.5 6 L 40.5 6 C 41.327 6 42 6.673 42 7.5 L 42 25.5 C 42 26.327 41.327 27 40.5 27 L 35.5 27 L 35.5 30 L 40.5 30 C 42.981 30 45 27.981 45 25.5 L 45 7.5 C 45 5.019 42.981 3 40.5 3 L 28.5 3 z M 17 10.5 C 14.519 10.5 12.5 12.519 12.5 15 L 12.5 16 L 15.5 16 L 15.5 15 C 15.5 14.173 16.173 13.5 17 13.5 L 29 13.5 C 29.827 13.5 30.5 14.173 30.5 15 L 30.5 33 C 30.5 33.827 29.827 34.5 29 34.5 L 26 34.5 L 26 37.5 L 29 37.5 C 31.481 37.5 33.5 35.481 33.5 33 L 33.5 21 L 38.5 21 A 1.50015 1.50015 0 1 0 38.5 18 L 33.5 18 L 33.5 15 L 38.5 15 A 1.50015 1.50015 0 1 0 38.5 12 L 32.5 12 A 1.50015 1.50015 0 0 0 32.333984 12.007812 C 31.509527 11.090101 30.327634 10.5 29 10.5 L 17 10.5 z M 7.5 18 C 5.0324991 18 3 20.032499 3 22.5 L 3 40.5 C 3 42.967501 5.0324991 45 7.5 45 L 19.5 45 C 21.967501 45 24 42.967501 24 40.5 L 24 22.5 C 24 20.032499 21.967501 18 19.5 18 L 7.5 18 z M 7.5 21 L 19.5 21 C 20.346499 21 21 21.653501 21 22.5 L 21 40.5 C 21 41.346499 20.346499 42 19.5 42 L 7.5 42 C 6.6535009 42 6 41.346499 6 40.5 L 6 22.5 C 6 21.653501 6.6535009 21 7.5 21 z M 9.5 27 A 1.50015 1.50015 0 1 0 9.5 30 L 17.5 30 A 1.50015 1.50015 0 1 0 17.5 27 L 9.5 27 z M 9.5 33 A 1.50015 1.50015 0 1 0 9.5 36 L 17.5 36 A 1.50015 1.50015 0 1 0 17.5 33 L 9.5 33 z" fill="#000000" />
        </svg>
    );
};

export default DocumentsSVG;
