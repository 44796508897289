import React, { useEffect } from "react";
import useForm from "@/res/hooks/Form/useForm";
import { resolvePath } from "@/res/helpers/ObjectHelpers";
import { useTranslation } from "react-i18next";

const useTextfieldInput = (props) => {
    const [t] = useTranslation();

    const { values, forceValue, resetValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "fieldInput";
    const elementId = `useFieldInput${key}`;
    const context = props?.context;
    const value = context != null ? resolvePath(context?.state, key) : values[key];

    useEffect(() => {
        if (value?.length > 0) {
            isValid();
        }
    }, [])

    useEffect(() => {
        if (context != null && props?.key != null) {
            autoExpand(null, true);
        }
    }, [value]);

    const setValue = (value) => {
        forceValue(key, value);
        autoExpand(null, true);
    };

    const isValid = (v = null) => {
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;
        let msg = "";

        if (rule?.required &&
            (_value == null || _value.length < 1)) {
            return false;
        }

        if (rule?.minLength != null &&
            (_value == null || _value.length < rule?.minLength)) {
            msg = t("_shared.error.too-short", { value: rule?.minLength, current: _value?.length ?? 0 });
            item?.setCustomValidity?.(msg.length > 0 ? msg : "The field is not valid.");
            return false;
        }

        if (rule?.maxLength != null &&
            (_value == null || _value.length > rule?.maxLength)) {
            msg = t("_shared.error.too-long", { value: rule?.maxLength, current: _value?.length ?? 0 });
            item?.setCustomValidity?.(msg.length > 0 ? msg : "The field is not valid.");
            return false;
        }

        item?.setCustomValidity?.("");
        return true;
    }

    const handleChangeMiddleman = (e) => {
        autoExpand(e?.target);
        handleChange(e)
        isValid(e?.target?.value);
    }

    const autoExpand = (el, force = false) => {
        if (el == null && force) {
            el = document?.getElementById?.(elementId);
        }

        if (el == null) {
            return;
        }

        // Reset field height
        el.style.height = "inherit";
        el.style.height = (el.scrollHeight + 2) + "px";
    };

    const render = () => {
        return (
            <div>
                { props?.label &&
                <label htmlFor={elementId}>
                    {props?.label}
                </label>
                }
                <textarea
                    id={ elementId }
                    name={ key }
                    placeholder={ props?.placeholder ?? t("_shared.form.textfield-placeholder") }
                    value={ value ?? ""}
                    onChange={ props?.onChange ?? handleChangeMiddleman }
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    // pattern={ `.{${rule?.minLength ?? 5},${rule?.maxLength}}` }
                    // title={ `Requires between ${rule?.minLength} to ${rule?.maxLength} characters` }
                    minLength={ rule?.minLength }
                    maxLength={ rule?.maxLength }
                    required={ rule?.required ?? true }
                    className={ props?.class }
                />
                <div className="input-attachment">
                    { rule?.maxLength > 0 &&
                        <small>
                            { value?.length ?? 0 } / { rule?.maxLength }
                        </small>
                    }
                </div>
            </div>
        );
    };

    return {
        value, render, isValid, setValue,
    };
};

export default useTextfieldInput;
