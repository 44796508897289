import React from "react";

const ClientFooter = (props) => {
    return (
        <footer>
        </footer>
    );
};

export default ClientFooter;
