import React, { useEffect } from "react";
import useForm from "@/res/hooks/Form/useForm";
import { resolvePath } from "@/res/helpers/ObjectHelpers";

const useHiddenInput = (props) => {
    const { values, forceValue } = useForm({ key: props?.key, context: props?.context });

    const context = props?.context;
    const key = props?.key ?? "hiddenInput";
    const value = context != null ? resolvePath(context?.state, key) : values[key];

    useEffect(() => {
        if ((value === "" || value == null) && props?.default != null) {
            setValue(props?.default);
        }
    }, [])

    const setValue = (value, overwrite = false) => {
        forceValue(key, value, overwrite);
    };

    return {
        value, setValue,
    };
};

export default useHiddenInput;
